import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import swal from 'sweetalert';

import config from '../app/config';
import mediaStreamInstance from '../app/services/mediaStream';
import socketInstance from '../app/services/socket';
import CurrentConnection from '../common/components/CurrentConnection';
import LoadingSpinner from '../common/components/LoadingSpinner';

import {
    setSessionInfo
} from '../app/slices/sessionSlice';

const menuItems = [
    {
        paths: ["/", "/profile"],
        label: "Hesabım",
        icon: "fa fa-user"
    },
    {
        paths: ["/createRoom"],
        label: "Yeni Oda",
        icon: "fa fa-plus"
    },
    {
        paths: ["/findRoom"],
        label: "Odaya Katıl",
        icon: "fa fa-chevron-circle-right"
    },
    {
        paths: ["/friends", "/friends/all", "/friends/blocked", "/friends/online", "/friends/waiting"],
        label: "Arkadaşlar",
        icon: "fa fa-users"
    },
    /*
    {
        paths: ["/messages"],
        label: "Mesajlar",
        icon: "fa fa-envelope-o"
    }
    */
];

const presenceLabels = {
    'available': <><i className="fa fa-circle m-r-sm text-navy"></i>Çevrimiçi</>,
    'busy': <><i className="fa fa-circle m-r-sm text-danger"></i>Meşgul</>,
    'away': <><i className="fa fa-circle m-r-sm text-warning"></i>Dışarıda</>,
    'invisible': <><i className="fa fa-circle m-r-sm text-muted"></i>Görünmez</>
};

const UserFrame = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentRoom } = useSelector((state) => state.room);
    const { sessionInfo } = useSelector((state) => state.session);

    const [isLoading, setIsLoading] = useState(true);
    const [notifications] = useState([]);

    const clearNotification = useCallback(() => {

    }, []);

    const logout = useCallback(() => {
        swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Onayla',
            text: 'Oturumu kapatmak istediğinize emin misiniz?',
            buttons: { confirm: 'Tamam', cancel: 'Vazgeç' }
        }).then(value => {
            if (value) {
                navigate('/logout');
            }
        });
    }, [navigate]);

    const updatePresenceStatus = useCallback((presenceStatus) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                presenceStatus
            })
        };

        fetch(`${config.restApi}presenceStatus`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        dispatch(setSessionInfo({ ...sessionInfo, presenceStatus }));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }));
    }, [dispatch, sessionInfo]);

    useEffect(() => {
        console.log('UserFrame: componentDidMount()');

        socketInstance.connect(() => setIsLoading(false));

        return () => {
            console.log('UserFrame: componentWillUnmount()');

            mediaStreamInstance.destroy();
            socketInstance.disconnect();
        };
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div id="wrapper">
            <nav className="navbar-default navbar-static-side" role="navigation">
                <div className="sidebar-collapse">
                    <ul className="nav metismenu" id="side-menu">
                        <li className="nav-header">
                            <div className="dropdown profile-element">
                                <Link className="dropdown-toggle" data-toggle="dropdown">
                                    <span className="block m-t-xs font-bold">Hoşgeldin {sessionInfo.username}</span>
                                    <span className="text-muted text-xs block">{presenceLabels[sessionInfo.presenceStatus]} <b className="caret"></b></span>
                                </Link>
                                <ul className="dropdown-menu animated fadeInRight m-t-xs">
                                    {Object.entries(presenceLabels).map(([presenceStatus, label]) => <li key={presenceStatus}><Link className="dropdown-item" onClick={() => updatePresenceStatus(presenceStatus)}>{label}</Link></li>)}
                                    <li className="dropdown-divider"></li>
                                    <li><Link className="dropdown-item" onClick={() => logout()}>Oturumu Kapat</Link></li>
                                </ul>
                            </div>
                            <div className="logo-element">Q</div>
                        </li>
                        {menuItems.map((menuItemInfo, index) => <li className={(menuItemInfo.paths.includes(window.location.pathname) && 'active') || ''} key={index}><Link title={menuItemInfo.label} to={menuItemInfo.paths[0]}><i className={menuItemInfo.icon}></i><span className="nav-label">{menuItemInfo.label}</span></Link></li>)}
                        {currentRoom && <CurrentConnection />}
                    </ul>
                </div>
            </nav>
            <div className="gray-bg" id="page-wrapper">
                {sessionInfo.isEmailVerified === 0 && <div className="alert alert-warning row m-b-none">E-posta adresiniz doğrulanmadı.<Link className="alert-link m-l-xs" to="/editProfile">Kontrol Et</Link>.</div>}
                <div className="row">
                    <nav className="navbar navbar-static-top white-bg m-b-none" role="navigation">
                        <div className="navbar-header">
                            <button className="navbar-minimalize minimalize-styl-2 btn btn-primary" onClick={() => document.body.classList.toggle('mini-navbar')}><i className="fa fa-bars"></i></button>
                        </div>
                        <ul className="nav navbar-top-links navbar-right">
                            <li className="dropdown">
                                <Link className="dropdown-toggle count-info" data-toggle="dropdown"><i className="fa fa-bell"></i>  <span className="label label-primary">{notifications.length}</span></Link>
                                <ul className="dropdown-menu dropdown-alerts">
                                    {notifications.map(notificationInfo => <li key={notificationInfo.id}><Link className="dropdown-item"><div><i className={notificationInfo.icon}></i> <small>{notificationInfo.title}</small><br />{notificationInfo.message}</div></Link></li>)}
                                    {notifications.length > 0 ? <li className="dropdown-divider"></li> : <li><span className="dropdown-item">Bekleyen bildiriminiz yok</span></li>}
                                    {notifications.length > 0 && <li><Link className="dropdown-item" onClick={() => clearNotification()}><div className="text-center"><strong>Bildirimleri Temizle</strong></div></Link></li>}
                                </ul>
                            </li>
                            <li>
                                <Link onClick={() => logout()}><i className="fa fa-sign-out"></i>Oturumu Kapat</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="row" style={{ height: sessionInfo.isEmailVerified === 0 ? 'calc(100% - 160px)' : 'calc(100% - 115px)' }}>
                    {children}
                </div>
                <div className="footer">
                    <div className="float-left">
                        <button className="btn btn-default btn-outline" onClick={() => document.body.classList.toggle('dark-mode')}><i className="fa fa-moon-o"></i></button>
                    </div>
                    <div className="float-right">
                        <strong>Copyright</strong> qwerup.com &copy; 2020 - 2025
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserFrame;