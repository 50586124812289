import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import swal from 'sweetalert';

import config from '../../app/config';
import { clearSession } from '../../app/slices/sessionSlice';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { removeCookie } from '../../common/utils/cookies';

const Logout = () => {
    const dispatch = useDispatch();
    const { sessionInfo, sessionType } = useSelector((state) => state.session);

    const logout = useCallback(() => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' }
        };

        fetch(config.restApi + (sessionType === 'user' ? 'logout' : 'guestLogout'), requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        removeCookie('sessionHash');
                        removeCookie('sessionType');

                        dispatch(clearSession());
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Yeniden Dene'
            }).then(() => logout()));
    }, [dispatch, sessionInfo, sessionType]);

    useEffect(() => {
        console.log('Logout: componentDidMount()');

        logout();

        return () => {
            console.log('Logout: componentWillUnmount()');
        };
    }, [logout]);

    return <LoadingSpinner />;
};

export default Logout;