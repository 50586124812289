import { configureStore } from '@reduxjs/toolkit';

import appReducer from './slices/appSlice';
import audioReducer from './slices/audioSlice';
import mediaStreamReducer from './slices/mediaStreamSlice';
import roomReducer from './slices/roomSlice';
import sessionReducer from './slices/sessionSlice';

const store = configureStore({
    reducer: {
        app: appReducer,
        audio: audioReducer,
        mediaStream: mediaStreamReducer,
        room: roomReducer,
        session: sessionReducer
    }
});

export default store;