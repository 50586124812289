import linux from '../../assets/images/linux.png';
import logo from '../../assets/images/logo.png';
import mac from '../../assets/images/mac.png';
import win from '../../assets/images/win.png';

const images = {
    linux,
    logo,
    mac,
    win
};

export default images;