import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    connectionStatus: 'disconnected',
    sessionInfo: null,
    sessionType: null
};

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        initializeSession: (state, action) => {
            const { sessionInfo, sessionType } = action.payload;
            state.sessionInfo = sessionInfo;
            state.sessionType = sessionType;
        },
        setConnectionStatus: (state, action) => {
            state.connectionStatus = action.payload;
        },
        setSessionInfo: (state, action) => {
            state.sessionInfo = action.payload;
        },
        setSessionType: (state, action) => {
            state.sessionType = action.payload;
        },
        clearSession: (state) => {
            state.connectionStatus = 'disconnected';
            state.sessionInfo = null;
            state.sessionType = null;
        }
    }
});

export const {
    initializeSession,
    setConnectionStatus,
    setSessionInfo,
    setSessionType,
    clearSession
} = sessionSlice.actions;

export default sessionSlice.reducer;