import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import swal from 'sweetalert';

import mediasoupInstance from '../../app/services/mediasoup';
import socketInstance from '../../app/services/socket';

import {
    setParticipantAudioSettings
} from '../../app/slices/audioSlice';

import {
    initializeRoom
} from '../../app/slices/roomSlice';

import DeviceSelectorModal from '../../common/components/DeviceSelectorModal';
import LoginModal from '../../common/components/LoginModal';

function adjustVideoLayout() {
    const container = document.getElementById('videoContainer');
    if (!container) return;

    const videos = container.getElementsByTagName('video');
    const videoCount = videos.length;
    if (videoCount === 0) return;

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    let bestLayout = { cellWidth: 0, cellHeight: 0, cols: 1, rows: 1 };
    let maxArea = 0;

    for (let cols = 1; cols <= videoCount; cols++) {
        const rows = Math.ceil(videoCount / cols);

        const wRatio = containerWidth / (cols * 16);
        const hRatio = containerHeight / (rows * 9);
        const scale = Math.min(wRatio, hRatio);

        const cellWidth = 16 * scale;
        const cellHeight = 9 * scale;
        const area = cellWidth * cellHeight;

        if (area > maxArea) {
            maxArea = area;
            bestLayout = { cellWidth, cellHeight, cols, rows };
        }
    }

    const totalGridWidth = bestLayout.cellWidth * bestLayout.cols;
    const totalGridHeight = bestLayout.cellHeight * bestLayout.rows;

    const offsetLeft = (containerWidth - totalGridWidth) / 2;
    const offsetTop = (containerHeight - totalGridHeight) / 2;

    for (let i = 0; i < videoCount; i++) {
        const video = videos[i];
        const col = i % bestLayout.cols;
        const row = Math.floor(i / bestLayout.cols);

        const x = offsetLeft + col * bestLayout.cellWidth;
        const y = offsetTop + row * bestLayout.cellHeight;

        video.style.position = 'absolute';
        video.style.left = `${x}px`;
        video.style.top = `${y}px`;
        video.style.objectFit = 'contain';
        video.style.setProperty('width', `${bestLayout.cellWidth}px`, 'important');
        video.style.setProperty('height', `${bestLayout.cellHeight}px`, 'important');
    }
}

const roomTypeDescription = {
    'public': 'Bu odaya herkes katılabilir.',
    'protected': 'Oda şifresini bilen herkes katılabilir.',
    'registered': 'Misafir kullanıcılar katılamaz.',
    'private': 'Sadece arkadaşlarınız katılabilir.'
}

const roomTypeRules = {
    'public': 'Bu odaya herkes katılabilir.',
    'protected': 'Lütfen oda şifresini giriniz.',
    'registered': 'Odaya katılmak için giriş yapmalısın.',
    'private': 'Odaya katılmak için giriş yapmalısın.'
}

const TemporaryRoom = () => {
    const dispatch = useDispatch();
    const { room } = useParams();
    const mainRef = useRef(null);
    const menuRef = useRef(null);
    const messageContentRef = useRef(null);
    const participantProducerRefs = useRef({});
    const playerRefs = useRef({});

    const {
        participantAudioSettings
    } = useSelector((state) => state.audio);

    const {
        camera,
        desktop,
        microphone
    } = useSelector((state) => state.mediaStream);

    const {
        currentRoom,
        currentRoomStatus,
        messages,
        participants,
        producers
    } = useSelector((state) => state.room);

    const {
        connectionStatus,
        sessionInfo,
        sessionType
    } = useSelector((state) => state.session);

    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [mediaStreams, setMediaStreams] = useState(() => {
        const filteredStreams = [];
        for (const mediaStream of mediasoupInstance.getMediaStreams().values()) {
            if (mediaStream.source !== 'userAudio') {
                filteredStreams.push(mediaStream);
            }
        }
        return filteredStreams;
    });
    const [menu, setMenu] = useState({ participant: null, visible: false, x: 0, y: 0 });
    const [message, setMessage] = useState('');
    const [password, setPassword] = useState('');
    const [roomInfo, setRoomInfo] = useState(null);
    const [roomType, setRoomType] = useState('public');
    const [showPassword, setShowPassword] = useState(false);
    const [showParticipants, setShowParticipants] = useState(true);

    const createNewRoom = useCallback((room, roomType, password) => {
        const socket = socketInstance.getSocket();
        socket.emit('createNewRoom', { room, roomType, password }, response => {
            switch (response.status) {
                case 200:
                    dispatch(initializeRoom({ room, password }));
                    break;
                case 203:
                case 500:
                    swal({
                        dangerMode: true,
                        icon: 'warning',
                        title: 'Hay Aksi',
                        text: response.message,
                        buttons: 'Tamam'
                    });
                    break;
                case 205:
                    socket.emit('roomInfo', { room }, response => {
                        if (response.status === 200) {
                            setRoomInfo(response.roomInfo);
                        } else {
                            setRoomInfo(null);
                        }
                    });
                    break;
                case 400:
                    break;
                default:
                    console.log(response);
                    break;
            }
        });
    }, [dispatch]);

    const joinExistingRoom = useCallback((room, password) => {
        const socket = socketInstance.getSocket();
        socket.emit('roomInfo', { room }, response => {
            if (response.status === 200) {
                dispatch(initializeRoom({ room, password }));
            } else {
                setRoomInfo(null);
            }
        });
    }, [dispatch]);

    const sendRoomMessage = useCallback(() => {
        if (!message) return;

        const socket = socketInstance.getSocket();
        socket.emit('sendRoomMessage', { message }, response => {
            if (response.status === 200) {
                setMessage('');
            }
        });
    }, [message]);

    const handleContextMenu = (event, participant) => {
        event.preventDefault();

        if (participant.appData.tag === sessionInfo.tag) return;

        if (mainRef.current) {
            const rect = mainRef.current.getBoundingClientRect();

            let posX = event.clientX - rect.left;
            let posY = event.clientY - rect.top;

            const menuWidth = 150;
            const menuHeight = 100;

            if (posX + menuWidth > rect.width) {
                posX -= menuWidth;
            }

            if (posY + menuHeight > rect.height) {
                posY -= menuHeight;
            }

            setMenu({
                participant,
                visible: true,
                x: posX,
                y: posY
            });
        }
    };

    const handleRangeChange = (participantId, source, value) => {
        dispatch(setParticipantAudioSettings({ participantId, source, value }));
    };

    useEffect(() => {
        console.log('TemporaryRoom: componentDidMount()');

        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenu(prevMenu => ({ ...prevMenu, visible: false }));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', adjustVideoLayout);

        return () => {
            console.log('TemporaryRoom: componentWillUnmount()');

            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', adjustVideoLayout);
        };
    }, []);

    useEffect(() => {
        const socket = socketInstance.getSocket();
        socket.emit('roomInfo', { room }, response => {
            if (response.status === 200) {
                setRoomInfo(response.roomInfo);
            } else {
                setRoomInfo(null);
            }

            setIsLoading(false);
        });
    }, [currentRoom, room]);

    useEffect(() => {
        if (currentRoomStatus === 'connected') {
            mediasoupInstance.on('addMediaStream', ({ id, peer, source, src }) => source !== 'userAudio' && setMediaStreams(prevStreams => prevStreams.some(prevStream => prevStream.id === id) ? prevStreams : [...prevStreams, { id, peer, source, src }]));
            mediasoupInstance.on('removeMediaStream', ({ id }) => setMediaStreams(prevStreams => prevStreams.filter(stream => stream.id !== id)));
        }
    }, [currentRoomStatus]);

    useEffect(() => adjustVideoLayout(), [mediaStreams]);

    useEffect(() => {
        if (!messageContentRef.current) {
            return;
        }

        const { clientHeight, scrollTop, scrollHeight } = messageContentRef.current;
        const isNearBottom = clientHeight + scrollTop >= scrollHeight * 0.9;

        if (isNearBottom) {
            messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        Object.entries(participantAudioSettings).forEach(([participantTag, audioSettings]) => {
            Object.entries(audioSettings).forEach(([source, volume]) => {
                const player = playerRefs.current[`${participantTag}_${source}`];

                if (!player) return;

                const newVolume = volume / 100;
                if (Math.abs(player.volume - newVolume) > 0.01) {
                    player.volume = newVolume;
                }
            });
        });
    }, [participantAudioSettings]);

    useEffect(() => {
        const producerIds = new Set([...producers.map(producer => producer.id), 'desktopAudio', 'desktopVideo', 'userAudio', 'userVideo']);

        setMediaStreams(prevMediaStreams => {
            const prevMediaStreamIds = new Set(prevMediaStreams.map(prevMediaStream => prevMediaStream.id));

            participantProducerRefs.current = {};

            producers.forEach(producer => {
                (participantProducerRefs.current[producer.peer.appData.tag] ??= []).push(producer);

                if (!prevMediaStreamIds.has(producer.id) && producer.appData.source !== 'userAudio') {
                    mediasoupInstance.consume(producer);
                }
            });

            return prevMediaStreams.filter(prevMediaStream => producerIds.has(prevMediaStream.id));
        });
    }, [producers]);

    useEffect(() => {
        if (showParticipants) {
            adjustVideoLayout();
        } else {
            setTimeout(adjustVideoLayout, 400);
        }
    }, [showParticipants]);

    if (connectionStatus === 'disconnected') {
        return (
            <div className="middle-box text-center">
                <div className="m-md">
                    <p>Yeniden bağlanılması bekleniyor...</p>
                </div>
            </div>
        );
    }

    if (isLoading) {
        return;
    }

    if (currentRoom !== room) {
        if (roomInfo) {
            return (
                <div className="ibox middle-box w-100">
                    <div className="ibox-title d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">#<b>{room}</b> odasına hoşgeldin.</h5>
                        <div className="ibox-tools"></div>
                    </div>
                    <div className="ibox-content">
                        {roomInfo.roomType === 'protected' && (
                            <div className="form-group row">
                                <label className="col-12 col-form-label">Oda Şifresi</label>
                                <div className="col-12 d-flex">
                                    <input className="form-control" onChange={event => setPassword(event.target.value)} placeholder="Oda Şifresi" type={showPassword ? "text" : "password"} value={password} />
                                    <span className="input-group-append">
                                        <button type="button" className="btn btn-default" onClick={() => setShowPassword(!showPassword)}>
                                            <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12">
                                * {roomTypeRules[roomInfo.roomType]}
                            </div>
                        </div>
                        <div className="row m-t-md">
                            <div className="col-12 d-flex flex-column align-items-center">
                                {sessionType === 'user' ? (
                                    <button className="btn btn-primary mb-2 w-100" onClick={() => joinExistingRoom(room, password)} type="button">
                                        @<b>{sessionInfo.username}</b> olarak katıl
                                    </button>
                                ) : ['registered', 'private'].includes(roomInfo.roomType) ? (
                                    <Link className="btn btn-success mt-2 w-100" to="/login">
                                        <i className="fa fa-sign-in m-r-xs"></i>Giriş Yap
                                    </Link>
                                ) : (
                                    <>
                                        <button className="btn btn-primary mb-2 w-100" onClick={() => joinExistingRoom(room, password)} type="button">
                                            <i className="fa fa-plus m-r-xs"></i><b>Misafir</b> olarak katıl
                                        </button>
                                        <span>- veya -</span>
                                        <button className="btn btn-success mt-2 w-100" onClick={() => setIsOpen(true)} type="button">
                                            <i className="fa fa-sign-in m-r-xs"></i>Giriş Yap
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {isOpen && (
                        <LoginModal
                            cancel={() => setIsOpen(false)}
                            success={() => setIsOpen(false)}
                        />
                    )}
                </div>
            );
        } else {
            return (
                <div className="ibox w-100">
                    <div className="ibox-title d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">Yeni Oda Oluştur</h5>
                        <div className="ibox-tools"></div>
                    </div>
                    <div className="ibox-content">
                        <div className="form-group row">
                            <label className="col-sm-3 col-lg-2 col-form-label">Oda Erişimi</label>
                            <div className="col-sm-9 col-lg-10">
                                <select className="form-control" onChange={event => setRoomType(event.target.value)} value={roomType}>
                                    <option value="public">Herkese Açık</option>
                                    <option value="protected">Şifre Korumalı</option>
                                    <option disabled={sessionType !== 'user'} value="registered">Sadece Üyeler</option>
                                    <option disabled={sessionType !== 'user'} value="private">Sadece Arkadaşlar</option>
                                </select>
                            </div>
                        </div>
                        {roomType === 'protected' && (
                            <div className="form-group row">
                                <label className="col-sm-3 col-lg-2 col-form-label">Oda Şifresi</label>
                                <div className="col-sm-9 col-lg-10 d-flex">
                                    <input className="form-control" onChange={event => setPassword(event.target.value)} placeholder="Oda Şifresi" type={showPassword ? "text" : "password"} value={password} />
                                    <span className="input-group-append">
                                        <button type="button" className="btn btn-default" onClick={() => setShowPassword(!showPassword)}>
                                            <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-sm-9 col-lg-10 offset-sm-3 offset-lg-2">
                                * {roomTypeDescription[roomType]}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12 d-flex justify-content-end">
                                <Link className="btn btn-white btn-sm m-b-md" to="/findRoom">
                                    <i className="fa fa-times mr-1"></i> Vazgeç
                                </Link>
                                <button className="btn btn-primary btn-sm m-b-md ml-2" onClick={() => createNewRoom(room, roomType, password)} type="button">
                                    <i className="fa fa-plus mr-1"></i> Oda Oluştur
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={`temporary-room ${!showParticipants ? 'hide-participants' : ''}`} ref={mainRef}>
            <div className="content">
                <div className={`players ${mediaStreams.length === 0 ? 'd-none' : ''}`} id="videoContainer">
                    {mediaStreams.map(mediaStream => {
                        if (mediaStream.source === 'desktopAudio') {
                            return <audio
                                autoPlay
                                key={mediaStream.id}
                                playsInline
                                ref={async (element) => {
                                    if (element && !element.srcObject) {
                                        element.srcObject = mediaStream.src;

                                        if ('setSinkId' in HTMLMediaElement.prototype) {
                                            element.setSinkId(await mediasoupInstance.getSelectedDeviceId('audiooutput'));
                                        }

                                        if (mediaStream.peer) {
                                            element.volume = (participantAudioSettings[mediaStream.peer.appData.tag]?.[mediaStream.source] ?? 50) / 100;
                                            playerRefs.current[`${mediaStream.peer.appData.tag}_${mediaStream.source}`] = element;
                                        }
                                    }
                                }}
                                webkit-playsinline="true"
                            />
                        } else {
                            return <video
                                autoPlay
                                controls={mediaStream.source !== 'userVideo'}
                                key={mediaStream.id}
                                muted
                                playsInline
                                ref={(element) => {
                                    if (element && !element.srcObject) {
                                        element.srcObject = mediaStream.src;

                                        if (mediaStream.peer) {
                                            playerRefs.current[`${mediaStream.peer.appData.tag}_${mediaStream.source}`] = element;
                                        }

                                        adjustVideoLayout();
                                    }
                                }}
                                style={mediaStream.source === 'userVideo' ? { transform: 'scaleX(-1)' } : {}}
                                title={mediaStream.peer?.appData?.displayName}
                                webkit-playsinline="true"
                            />
                        }
                    })}
                </div>
                <div className={`messages ${mediaStreams.length === 0 ? 'h-100' : ''}`} ref={messageContentRef}>
                    <div className="media-body p-sm">
                        <div className="input-group">
                            <input className="form-control" defaultValue={window.location.href} readOnly type="text" />
                            <div className="input-group-append"><button className="btn btn-outline-secondary" onClick={() => navigator.clipboard.writeText(window.location.href)} type="button">Kopyala</button></div>
                        </div>
                    </div>
                    {messages.map(message =>
                        <div className="media-body p-w-sm m-t-sm" key={message.id}>
                            <small className="float-right text-navy">{message.date}</small>
                            <strong>{message.from.displayName}</strong>
                            <p className="m-b-xs">{message.message}</p>
                        </div>
                    )}
                </div>
            </div>
            <ul className="participants no-select">
                {participants.map(participant => {
                    const producers = participantProducerRefs.current[participant.appData.tag] || [];
                    const hasDesktopVideo = producers.some(producer => producer.appData.source === 'desktopVideo');
                    const hasUserAudio = producers.some(producer => producer.appData.source === 'userAudio');
                    const hasUserVideo = producers.some(producer => producer.appData.source === 'userVideo');
                    return (
                        <li key={participant.id}
                            onClick={(event) => handleContextMenu(event, participant)}
                            onContextMenu={(event) => handleContextMenu(event, participant)}>
                            {participant.appData.displayName}
                            {hasUserAudio && <i className="fa fa-microphone m-l-xs"></i>}
                            {hasUserVideo && <i className="fa fa-video-camera m-l-xs"></i>}
                            {hasDesktopVideo && <i className="fa fa-laptop m-l-xs"></i>}
                        </li>
                    );
                })}
            </ul>
            <div className="participant-control" onClick={() => setShowParticipants(!showParticipants)}>
                {showParticipants ? <i className="fa fa-angle-double-right"></i> : <i className="fa fa-angle-double-left"></i>}
            </div>
            <div className="action">
                <div className="form-group">
                    <textarea
                        className="form-control b-r-md"
                        onChange={(event) => setMessage(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();

                                if (event.shiftKey) {
                                    setMessage((prev) => `${prev}\n`);
                                } else if (message) {
                                    sendRoomMessage();
                                }
                            }
                        }}
                        placeholder="Bir şeyler yaz"
                        value={message}
                    />
                </div>
                <div className="text-right">
                    {microphone ?
                        <button className="btn btn-sm m-t-n-xs" onClick={() => mediasoupInstance.stopUserAudio()} title="Mikrofonu Kapat" type="button"><i className="fa fa-microphone text-success"></i></button>
                        :
                        <button className="btn btn-sm m-t-n-xs" onClick={() => mediasoupInstance.startUserAudio()} title="Mikrofonu Aç" type="button"><i className="fa fa-microphone-slash"></i></button>
                    }

                    {camera ?
                        <button className="btn btn-sm m-t-n-xs" onClick={() => mediasoupInstance.stopUserVideo()} title="Kamerayı Kapat" type="button"><i className="fa fa-video-camera text-success"></i></button>
                        :
                        <button className="btn btn-sm m-t-n-xs" onClick={() => mediasoupInstance.startUserVideo()} title="Kamerayı Aç" type="button"><i className="fa fa-video-camera"></i></button>
                    }

                    {desktop ?
                        <button className="btn btn-sm m-t-n-xs" onClick={() => mediasoupInstance.stopDesktop()} title="Ekran Paylaşımını Kapat" type="button"><i className="fa fa-laptop text-success"></i></button>
                        :
                        <button className="btn btn-sm m-t-n-xs" onClick={() => mediasoupInstance.startDesktop()} title="Ekran Paylaşımını Aç" type="button"><i className="fa fa-laptop"></i></button>
                    }

                    <button className="btn btn-sm m-t-n-xs" onClick={() => setIsOpen(true)} title="Ses ve Görüntü Ayarları" type="button"><i className="fa fa-cog"></i></button>
                    <button className="btn btn-sm btn-primary m-t-n-xs" onClick={() => sendRoomMessage()}>Gönder #<strong>{currentRoom}</strong></button>
                </div>
            </div>
            {menu.visible && (
                <div
                    ref={menuRef}
                    className="no-select"
                    style={{
                        position: "absolute",
                        top: `${menu.y}px`,
                        left: `${menu.x}px`,
                        background: "#fff",
                        boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                        color: '#676a6c',
                        padding: "10px",
                        borderRadius: "5px",
                        zIndex: 1000
                    }}>
                    <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                        {menu.participant.appData.displayName}<button className="btn btn-xs" onClick={() => navigator.clipboard.writeText(menu.participant.appData.displayName)} title="Kopyala" type="button"><i className="fa fa-copy"></i></button>
                    </p>
                    <p>Kullanıcı Sesi</p>
                    <input className="slider" max="100" min="0" onChange={(event) => handleRangeChange(menu.participant.appData.tag, 'userAudio', parseInt(event.target.value))} type="range" value={participantAudioSettings[menu.participant.appData.tag]?.userAudio ?? 100} />
                    <p className="m-t">Medya Sesi</p>
                    <input className="slider" max="100" min="0" onChange={(event) => handleRangeChange(menu.participant.appData.tag, 'desktopAudio', parseInt(event.target.value))} type="range" value={participantAudioSettings[menu.participant.appData.tag]?.desktopAudio ?? 50} />
                </div>
            )}
            {isOpen && (
                <DeviceSelectorModal
                    cancel={() => setIsOpen(false)}
                    success={() => setIsOpen(false)}
                />
            )}
        </div>
    );
};

export default TemporaryRoom;