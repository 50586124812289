import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import config from '../../app/config';
import logo from '../../assets/images/logo.png';

import ChangePasswordModal from '../../common/components/ChangePasswordModal';

const Profile = () => {
    const navigate = useNavigate();
    const { sessionInfo } = useSelector((state) => state.session);

    const [isOpen, setIsOpen] = useState(false);
    const [accountStats, setAccountStats] = useState(null);

    useEffect(() => {
        console.log('Profile: componentDidMount()');

        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}` }
        };

        fetch(`${config.restApi}accountStats`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        setAccountStats({ friends: responseData.friends, waiting: responseData.waiting })
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => { });

        return () => {
            console.log('Profile: componentWillUnmount()');
        };
    }, [sessionInfo.sessionHash]);

    return (
        <div className="ibox w-100">
            <div className="ibox-title">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item cursor-pointer" onClick={() => navigate('/profile')}><i className="fa fa-home m-r-xs"></i>Hesabım</li>
                </ol>
            </div>
            <div className="ibox-content">
                <div className="row m-b-lg m-t-lg">
                    <div className="col-md-9">
                        <div className="profile-image">
                            <img alt="profile" className="m-b-md" src={logo} />
                        </div>
                        <div className="profile-info">
                            <div>
                                <div>
                                    <h2 className="no-margins">{sessionInfo.firstname} {sessionInfo.lastname}</h2>
                                    <h4>{sessionInfo.username}</h4>
                                    <Link className="btn btn-white btn-sm m-r-sm m-b-sm" to="/editProfile"><i className="fa fa-edit m-r-xs"></i>Bilgilerimi Düzenle</Link>
                                    <button className="btn btn-white btn-sm m-r-sm m-b-sm" onClick={() => setIsOpen(true)} type="button"><i className="fa fa-lock m-r-xs"></i>Şifremi Değiştir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {accountStats && (
                        <div className="col-md-3">
                            <table className="table small m-b-xs">
                                <tbody>
                                    <tr>
                                        <td className="cursor-pointer" onClick={() => navigate('/friends/all')}><strong>{accountStats.friends}</strong> arkadaş</td>
                                    </tr>
                                    <tr>
                                        <td className="cursor-pointer" onClick={() => navigate('/friends/waiting')}><strong>{accountStats.waiting}</strong> bekleyen istek</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
            {isOpen && (
                <ChangePasswordModal
                    cancel={() => setIsOpen(false)}
                    success={() => setIsOpen(false)}
                />
            )}
        </div>
    );
};

export default Profile;