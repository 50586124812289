import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../app/config';
import LoadingSpinner from '../../common/components/LoadingSpinner';

const EmailActivation = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const doJob = useCallback(() => {
        const validationHash = searchParams.get('validationHash');
        let validationMessage = '';

        if (!validationHash) {
            validationMessage = 'Doğrulama kodu boş bırakılamaz.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            }).then(() => navigate('/'));

            return;
        }

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                validationHash
            })
        };

        fetch(`${config.restApi}emailActivation`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        swal({
                            dangerMode: false,
                            icon: 'success',
                            title: 'Tamamlandı',
                            text: 'Eposta adresiniz başarıyla doğrulandı.',
                            buttons: 'Tamam'
                        }).then(() => navigate('/'));
                        break;
                    case 203:
                        swal({
                            dangerMode: true,
                            icon: 'info',
                            title: 'Eksik Bilgi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => navigate('/'));
                        break;
                    case 205:
                    case 404:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => navigate('/'));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Yeniden Dene'
                        }).then(() => doJob());
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Yeniden Dene'
            }).then(() => doJob()));
    }, [navigate, searchParams]);

    useEffect(() => {
        console.log('EmailActivation: componentDidMount()');

        doJob();

        return () => {
            console.log('EmailActivation: componentWillUnmount()');
        };
    }, [doJob]);

    return <LoadingSpinner />;
};

export default EmailActivation;