import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../app/config';

import {
    initializeSession
} from '../../app/slices/sessionSlice';

import ReAuthModal from '../../common/components/ReAuthModal';

const EditProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { sessionInfo } = useSelector((state) => state.session);

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [firstname, setFirstname] = useState(sessionInfo.firstname);
    const [lastname, setLastname] = useState(sessionInfo.lastname);
    const [username, setUsername] = useState(sessionInfo.username);
    const [email, setEmail] = useState(sessionInfo.email);

    const emailActivation = useCallback(() => {
        let validationMessage = '';

        if (email !== sessionInfo.email) {
            validationMessage = 'Lütfen değişiklikleri kayıt edip tekrar deneyiniz.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' }
        };

        fetch(`${config.restApi}emailActivation`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        swal({
                            dangerMode: false,
                            icon: 'success',
                            title: 'İşlem tamamlandı',
                            text: `Doğrulama bağlantısı ${sessionInfo.email} adresine gönderildi.`,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false))
                        break;
                    case 400:
                        break;
                    case 205:
                    case 403:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [sessionInfo, email]);

    const onSubmit = useCallback((event) => {
        event?.preventDefault();

        let validationMessage = '';

        if (!firstname) {
            validationMessage = 'Adı boş bırakılamaz.';
        } else if (!lastname) {
            validationMessage = 'Soyadı boş bırakılamaz.';
        } else if (!username) {
            validationMessage = 'Kullanıcı Adı boş bırakılamaz.';
        } else if (!email) {
            validationMessage = 'E-Posta adresi boş bırakılamaz.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                firstname,
                lastname,
                username,
                email
            })
        };

        fetch(`${config.restApi}editProfile`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        dispatch(initializeSession({ sessionInfo: { ...sessionInfo, firstname, lastname, username, email, isEmailVerified: email === sessionInfo.email ? sessionInfo.isEmailVerified : 0 }, sessionType: 'user' }));

                        swal({
                            dangerMode: false,
                            icon: 'success',
                            title: 'İşlem tamamlandı',
                            text: 'Bilgiler başarıyla güncellendi.',
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 203:
                        swal({
                            dangerMode: true,
                            icon: 'info',
                            title: 'Eksik Bilgi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 400:
                        break;
                    case 205:
                    case 403:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 406:
                        setIsLoading(false);
                        setIsOpen(true);
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [dispatch, sessionInfo, firstname, lastname, username, email]);

    useEffect(() => {
        console.log('EditProfile: componentDidMount()');

        return () => {
            console.log('EditProfile: componentWillUnmount()');
        };
    }, []);

    return (
        <div className="ibox w-100">
            <div className="ibox-title">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item cursor-pointer" onClick={() => navigate('/profile')}><i className="fa fa-home m-r-xs"></i>Hesabım</li>
                    <li className="breadcrumb-item">Bilgilerimi Düzenle</li>
                </ol>
            </div>
            <form onSubmit={onSubmit}>
                <div className={isLoading ? "ibox-content sk-loading" : "ibox-content"}>
                    <div className="sk-spinner sk-spinner-fading-circle">
                        <div className="sk-circle1 sk-circle"></div>
                        <div className="sk-circle2 sk-circle"></div>
                        <div className="sk-circle3 sk-circle"></div>
                        <div className="sk-circle4 sk-circle"></div>
                        <div className="sk-circle5 sk-circle"></div>
                        <div className="sk-circle6 sk-circle"></div>
                        <div className="sk-circle7 sk-circle"></div>
                        <div className="sk-circle8 sk-circle"></div>
                        <div className="sk-circle9 sk-circle"></div>
                        <div className="sk-circle10 sk-circle"></div>
                        <div className="sk-circle11 sk-circle"></div>
                        <div className="sk-circle12 sk-circle"></div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-lg-2 col-form-label">Adı</label>
                        <div className="col-sm-9 col-lg-10">
                            <input className="form-control" onChange={(event) => setFirstname(event.target.value)} type="text" value={firstname} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-lg-2 col-form-label">Soyadı</label>
                        <div className="col-sm-9 col-lg-10">
                            <input className="form-control" onChange={(event) => setLastname(event.target.value)} type="text" value={lastname} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-lg-2 col-form-label">Kullanıcı Adı</label>
                        <div className="col-sm-9 col-lg-10">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-addon"><strong>@</strong></span>
                                </div>
                                <input type="text" className="form-control" onChange={(event) => setUsername(event.target.value)} value={username} />
                            </div>
                            <span className="form-text">Kullanıcı adınızda a-z, A-Z (türkçe karakterler hariç), 0-9, "_" ve "." karakterlerini kullanabilirsiniz.</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-lg-2 col-form-label">E-Posta</label>
                        <div className="col-sm-9 col-lg-10">
                            <div className="input-group">
                                {sessionInfo.isEmailVerified === 0 && <span className="input-group-prepend"><button className="btn btn-success" onClick={() => emailActivation()} type="button"><i className="fa fa-check-square-o m-r-xs"></i>Şimdi Doğrula</button></span>}
                                <input className="form-control" onChange={(event) => setEmail(event.target.value)} type="email" value={email} />
                            </div>
                            {sessionInfo.isEmailVerified === 1 ? <span className="form-text text-success">Doğrulandı</span> : <span className="form-text text-danger">Doğrulanmadı</span>}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <Link className="btn btn-white btn-sm m-b-md" to="/profile"><i className="fa fa-times m-r-xs"></i>Vazgeç</Link>
                            <button className="btn btn-primary btn-sm m-b-md m-l-xs" type="submit"><i className="fa fa-save m-r-xs"></i>Değişiklikleri Kaydet</button>
                        </div>
                    </div>
                </div>
            </form>
            {isOpen && (
                <ReAuthModal
                    cancel={() => setIsOpen(false)}
                    success={() => {
                        setIsOpen(false);
                        onSubmit();
                    }}
                />
            )}
        </div>
    );
};

export default EditProfile;