import { io } from 'socket.io-client';

import config from '../config';
import store from '../store';

import {
    setCurrentRoomStatus,
    addParticipant,
    removeParticipant,
    addProducer,
    removeProducer,
    addMessage
} from '../slices/roomSlice';

import {
    setConnectionStatus
} from '../slices/sessionSlice';

import soundManagerInstance from './soundManager';

class SocketService {
    static instance = null;

    constructor() {
        if (SocketService.instance) {
            return SocketService.instance;
        }

        this.socket = null;

        SocketService.instance = this;
    }

    connect(callback) {
        console.log('SocketService: connect()');

        if (this.socket) return;

        const sessionData = store.getState().session;

        this.socket = io(`https://${config.host}:8080`);

        this.socket.on('connect', () => {
            console.log('Socket connected');

            this.socket.emit('initializeSession', {
                sessionHash: sessionData.sessionInfo.sessionHash,
                sessionType: sessionData.sessionType
            }, response => {
                callback(response);

                store.dispatch(setConnectionStatus('connected'));
            });
        });

        this.socket.on('connect_error', error => console.log(`Socket error: ${error.message}`));

        this.socket.on('disconnect', () => {
            console.log('Socket disconnected');

            store.dispatch(setConnectionStatus('disconnected'));
            store.dispatch(setCurrentRoomStatus('disconnected'));
        });

        this.socket.on('participantJoined', participant => {
            soundManagerInstance.playSound('participantJoined');
            store.dispatch(addParticipant(participant));
        });
        this.socket.on('participantLeft', participant => {
            soundManagerInstance.playSound('participantLeft');
            store.dispatch(removeParticipant(participant));
        });
        this.socket.on('addProducer', producer => store.dispatch(addProducer(producer)));
        this.socket.on('removeProducer', producer => store.dispatch(removeProducer(producer)));
        this.socket.on('roomNewMessage', message => {
            message.date = new Intl.DateTimeFormat('tr-TR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).format(new Date());

            const appData = store.getState().app;
            !appData.isFocused && soundManagerInstance.playSound('roomNewMessage');

            store.dispatch(addMessage(message));
        });

        this.socket.request = (eventName, payload = {}) => new Promise((resolve, _reject) => this.socket.emit(eventName, payload, response => resolve(response)));
    }

    disconnect() {
        console.log('SocketService: disconnect()');

        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }

    getSocket() {
        return this.socket;
    }
}

const socketInstance = new SocketService();

export default socketInstance;