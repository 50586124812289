import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    camera: false,
    desktop: false,
    microphone: false
};

const mediaStreamSlice = createSlice({
    name: 'mediaStream',
    initialState,
    reducers: {
        setCamera: (state, action) => {
            state.camera = action.payload;
        },
        setDesktop: (state, action) => {
            state.desktop = action.payload;
        },
        setMicrophone: (state, action) => {
            state.microphone = action.payload;
        },
        toggleCamera: (state) => {
            state.camera = !state.camera;
        },
        toggleDesktop: (state) => {
            state.desktop = !state.desktop;
        },
        toggleMicrophone: (state) => {
            state.microphone = !state.microphone;
        }
    }
});

export const {
    setCamera,
    setDesktop,
    setMicrophone,
    toggleCamera,
    toggleDesktop,
    toggleMicrophone
} = mediaStreamSlice.actions;

export default mediaStreamSlice.reducer;