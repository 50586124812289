import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    participantAudioSettings: {}
};

const audioSlice = createSlice({
    name: 'audio',
    initialState,
    reducers: {
        setParticipantAudioSettings: (state, action) => {
            const { participantId, source, value } = action.payload;
            if (!state.participantAudioSettings[participantId]) {
                state.participantAudioSettings[participantId] = {};
            }
            state.participantAudioSettings[participantId][source] = value;
        }
    }
});

export const {
    setParticipantAudioSettings
} = audioSlice.actions;

export default audioSlice.reducer;