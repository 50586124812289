import roomNewMessage from '../../assets/sounds/roomNewMessage.mp3';
import mute from '../../assets/sounds/mute.mp3';
import unmute from '../../assets/sounds/unmute.mp3';
import participantJoined from '../../assets/sounds/participantJoined.mp3';
import participantLeft from '../../assets/sounds/participantLeft.mp3';

class SoundManagerService {
    static instance = null;

    constructor() {
        if (SoundManagerService.instance) {
            return SoundManagerService.instance;
        }

        this.currentOutputDevice = null;

        this.sounds = {
            roomNewMessage: new Audio(roomNewMessage),
            mute: new Audio(mute),
            unmute: new Audio(unmute),
            participantJoined: new Audio(participantJoined),
            participantLeft: new Audio(participantLeft)
        };

        SoundManagerService.instance = this;
    }

    playSound(soundName) {
        if (this.sounds[soundName]) {
            this.sounds[soundName].play();
        }
    }

    async setSinkId(deviceId) {
        if (!deviceId) return;

        if (!('setSinkId' in HTMLMediaElement.prototype)) {
            console.warn(`Tarayıcınız 'setSinkId' desteğine sahip değil. Ses çıkışı değiştirilemez.`);
            return;
        }

        this.currentOutputDevice = deviceId;

        try {
            await Promise.all(Object.entries(this.sounds).map(async ([key, soundElement]) => {
                if (!soundElement || typeof soundElement.setSinkId !== 'function') {
                    console.warn(`${key} için 'setSinkId' desteklenmiyor.`);
                    return;
                }

                await soundElement.setSinkId(deviceId);

                console.log('Ses çıkışı değiştirildi:', deviceId);
            }));
        } catch (error) {
            console.error('Ses çıkış aygıtı değiştirilemedi:', error);
        }
    }
}

const soundManagerInstance = new SoundManagerService();

export default soundManagerInstance;