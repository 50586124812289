import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import swal from 'sweetalert';

const patternRoom = /^[a-zA-Z0-9._]+$/;

const FindRoom = () => {
    const navigate = useNavigate();

    const [room, setRoom] = useState('');

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        let validationMessage = '';

        if (!room) {
            validationMessage = 'Oda adını kontrol ediniz.';
        } else if (!patternRoom.test(room)) {
            validationMessage = 'Oda adınızda geçersiz karakterler var.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        navigate(`/${room}`);
    }, [navigate, room]);

    useEffect(() => {
        console.log('FindRoom: componentDidMount()');

        return () => {
            console.log('FindRoom: componentWillUnmount()');
        };
    }, []);

    return (
        <div className="middle-box text-center w-100">
            <form onSubmit={onSubmit}>
                <div className="m-md">
                    <div className="input-group">
                        <input className="form-control" onChange={event => setRoom(event.target.value)} placeholder="Oda Adını Girin" type="text" value={room} />
                        <span className="input-group-append"><button className="btn btn-success" type="submit"><i className="fa fa-chevron-circle-right m-r-xs"></i>Katıl</button></span>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FindRoom;