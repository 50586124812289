import { Device } from 'mediasoup-client';

import mediaStreamInstance from './mediaStream';
import socketInstance from './socket';

class MediasoupService {
    static instance = null;

    constructor() {
        if (MediasoupService.instance) {
            return MediasoupService.instance;
        }

        this.device = null;

        this.recvTransport = null;
        this.sendTransport = null;

        this.consumers = new Map();
        this.producers = new Map();

        this.eventListeners = new Map();
        this.mediaStreams = new Map();

        MediasoupService.instance = this;
    }

    emit(event, data) {
        if (this.eventListeners.has(event)) {
            this.eventListeners.get(event).forEach(callback => callback(data));
        }
    }

    off(event, callback = null) {
        if (!this.eventListeners.has(event)) return;

        if (callback) {
            this.eventListeners.get(event).delete(callback);

            if (this.eventListeners.get(event).size === 0) {
                this.eventListeners.delete(event);
            }
        } else {
            this.eventListeners.delete(event);
        }
    }

    on(event, callback) {
        if (!this.eventListeners.has(event)) {
            this.eventListeners.set(event, new Set());
        }

        this.eventListeners.get(event).add(callback);
    }

    async init() {
        if (this.device) return;

        const socket = socketInstance.getSocket();

        const response = await socket.request('getRtpCapabilities', {});
        if (response?.status === 200) {
            this.device = new Device();
            await this.device.load({ routerRtpCapabilities: response.rtpCapabilities });

            const recvTransportResponse = await socket.request('createWebRtcTransport', { forceTcp: false });
            if (recvTransportResponse?.status === 200) {
                this.recvTransport = this.device.createRecvTransport(recvTransportResponse.params);
                this.setupTransportEvents(this.recvTransport, 'recv');
            }

            const sendTransportResponse = await socket.request('createWebRtcTransport', { forceTcp: false, rtpCapabilities: this.device.rtpCapabilities });
            if (sendTransportResponse?.status === 200) {
                this.sendTransport = this.device.createSendTransport(sendTransportResponse.params);
                this.setupTransportEvents(this.sendTransport, 'send');
                this.setupProduceEvent(this.sendTransport);
            }
        }
    }

    closeTransports() {
        if (this.recvTransport) this.recvTransport.close();
        if (this.sendTransport) this.sendTransport.close();
        this.recvTransport = null;
        this.sendTransport = null;
        this.consumers.clear();
        this.producers.clear();
    }

    async destroy() {
        this.closeTransports();
        this.eventListeners.clear();
        this.mediaStreams.clear();
        this.device = null;
    }

    async findDeviceId(kind, deviceKey) {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const filteredDevices = devices.filter(device => device.kind === kind);
            const matchedDevice = filteredDevices.find(device => device?.label === deviceKey || device?.groupId === deviceKey || device?.deviceId === deviceKey);
            return matchedDevice ? matchedDevice.deviceId : (filteredDevices.length > 0 ? filteredDevices[0].deviceId : null);
        } catch (error) {
        }

        return null;
    }

    async getSelectedDeviceId(kind) {
        let selectedDevices = {};

        try {
            selectedDevices = JSON.parse(localStorage.getItem('selectedDevices')) || {};
        } catch (error) {
            localStorage.removeItem('selectedDevices');
        }

        switch (kind) {
            case 'audioinput':
                return await this.findDeviceId(kind, selectedDevices.audioInput);
            case 'audiooutput':
                return await this.findDeviceId(kind, selectedDevices.audioOutput);
            case 'videoinput':
                return await this.findDeviceId(kind, selectedDevices.videoInput);
            default:
                return null;
        }
    }

    async startDesktop() {
        const mediaStream = await mediaStreamInstance.startDesktop();

        if (!mediaStream) return;

        const videoTrack = mediaStream.getVideoTracks()[0];
        videoTrack.onended = () => this.stopDesktop();
        const videoProducer = await this.produce('desktopVideo', videoTrack);
        if (videoProducer) {
            this.mediaStreams.set('desktopVideo', { id: 'desktopVideo', source: 'desktopVideo', src: mediaStream });
            this.emit('addMediaStream', { id: 'desktopVideo', source: 'desktopVideo', src: mediaStream });

            const audioTrack = mediaStream.getAudioTracks()[0];
            if (audioTrack) {
                const audioProducer = await this.produce('desktopAudio', audioTrack);
                if (!audioProducer) {
                    mediaStreamInstance.stopDesktop();
                }
            }
        } else {
            mediaStreamInstance.stopDesktop();
        }
    }

    async startUserAudio(deviceId = null) {
        deviceId ??= await this.getSelectedDeviceId('audioinput');
        const mediaStream = await mediaStreamInstance.startUserAudio(deviceId);

        if (!mediaStream) return;

        const track = mediaStream.getAudioTracks()[0];
        track.onended = () => this.stopUserAudio();
        const producer = await this.produce('userAudio', track);
        if (producer) {
            this.mediaStreams.set('userAudio', { id: 'userAudio', source: 'userAudio', src: mediaStream });
            this.emit('addMediaStream', { id: 'userAudio', source: 'userAudio', src: mediaStream });
        } else {
            mediaStreamInstance.stopUserAudio();
        }
    }

    async startUserVideo(deviceId = null) {
        deviceId ??= await this.getSelectedDeviceId('videoinput');
        const mediaStream = await mediaStreamInstance.startUserVideo(deviceId);

        if (!mediaStream) return;

        const track = mediaStream.getVideoTracks()[0];
        track.onended = () => this.stopUserVideo();
        const producer = await this.produce('userVideo', track);
        if (producer) {
            this.mediaStreams.set('userVideo', { id: 'userVideo', source: 'userVideo', src: mediaStream });
            this.emit('addMediaStream', { id: 'userVideo', source: 'userVideo', src: mediaStream });
        } else {
            mediaStreamInstance.stopUserVideo();
        }
    }

    stopDesktop() {
        mediaStreamInstance.stopDesktop();
        this.closeProducer('desktopAudio');
        this.closeProducer('desktopVideo');
        this.mediaStreams.delete('desktopAudio');
        this.mediaStreams.delete('desktopVideo');
        this.emit('removeMediaStream', { id: 'desktopVideo' });
    }

    stopUserAudio() {
        mediaStreamInstance.stopUserAudio();
        this.closeProducer('userAudio');
        this.mediaStreams.delete('userAudio');
        this.emit('removeMediaStream', { id: 'userAudio' });
    }

    stopUserVideo() {
        mediaStreamInstance.stopUserVideo();
        this.closeProducer('userVideo');
        this.mediaStreams.delete('userVideo');
        this.emit('removeMediaStream', { id: 'userVideo' });
    }

    async reconnect() {
        const desktopStream = mediaStreamInstance.getDesktopStream();
        if (desktopStream) {
            const videoTrack = desktopStream.getVideoTracks()[0];
            const videoProducer = await this.produce('desktopVideo', videoTrack);
            if (videoProducer) {
                this.mediaStreams.set('desktopVideo', { id: 'desktopVideo', source: 'desktopVideo', src: desktopStream });
                this.emit('addMediaStream', { id: 'desktopVideo', source: 'desktopVideo', src: desktopStream });

                const audioTrack = desktopStream.getAudioTracks()[0];
                if (audioTrack) {
                    const audioProducer = await this.produce('desktopAudio', audioTrack);
                    if (!audioProducer) {
                        this.stopDesktop();
                    }
                }
            } else {
                this.stopDesktop();
            }
        }

        const userAudioStream = mediaStreamInstance.getUserAudioStream();
        if (userAudioStream) {
            const track = userAudioStream.getAudioTracks()[0];
            const producer = await this.produce('userAudio', track);
            if (producer) {
                this.mediaStreams.set('userAudio', { id: 'userAudio', source: 'userAudio', src: userAudioStream });
                this.emit('addMediaStream', { id: 'userAudio', source: 'userAudio', src: userAudioStream });
            } else {
                this.stopUserAudio();
            }
        }

        const userVideoStream = mediaStreamInstance.getUserVideoStream();
        if (userVideoStream) {
            const track = userVideoStream.getVideoTracks()[0];
            const producer = await this.produce('userVideo', track);
            if (producer) {
                this.mediaStreams.set('userVideo', { id: 'userVideo', source: 'userVideo', src: userVideoStream });
                this.emit('addMediaStream', { id: 'userVideo', source: 'userVideo', src: userVideoStream });
            } else {
                this.stopUserVideo();
            }
        }
    }

    setupProduceEvent(transport) {
        const socket = socketInstance.getSocket();

        transport.on('produce', ({ kind, rtpParameters, appData }, callback, errback) => socket.emit('produce', { transportId: transport.id, kind, rtpParameters, appData }, response => {
            if (response.status === 200) {
                callback({ id: response.producerId });
            } else {
                errback(response.message);
            }
        }));
    }

    setupTransportEvents(transport, type) {
        const socket = socketInstance.getSocket();

        transport.on('connect', ({ dtlsParameters }, callback, errback) => socket.emit('connectTransport', { transportId: transport.id, dtlsParameters }, response => {
            if (response.status === 200) {
                callback();
            } else {
                errback(response.message);
            }
        }));

        transport.on('connectionstatechange', state => console.log(`${type} transport durumu:`, state));
    }

    consume(producer) {
        return new Promise((resolve, reject) => {
            if (!this.recvTransport) {
                reject(new Error('consume çağrıldı ama recvTransport mevcut değil.'));
                return;
            }

            if (this.mediaStreams.has(producer.id)) {
                reject(new Error('Bu stream zaten açık.'));
                return;
            }

            const { rtpCapabilities } = this.device;
            const socket = socketInstance.getSocket();

            socket.emit('consume', {
                transportId: this.recvTransport.id,
                producerId: producer.id,
                rtpCapabilities
            }, async response => {
                if (response.status === 200) {
                    try {
                        const consumer = await this.recvTransport.consume({ ...response.params, appData: producer.appData });
                        consumer.on('trackended', () => console.log('trackended'));
                        consumer.on('transportclose', () => console.log('transportclose'));

                        const mediaStream = new MediaStream();
                        mediaStream.addTrack(consumer.track);

                        const result = {
                            id: producer.id,
                            peer: producer.peer,
                            source: producer.appData.source,
                            src: mediaStream
                        };

                        this.consumers.set(consumer.id, consumer);
                        this.mediaStreams.set(producer.id, result);
                        this.emit('addMediaStream', result);

                        resolve(result);
                    } catch (err) {
                        reject(err);
                    }
                } else {
                    reject(new Error(response.message));
                }
            });
        });
    }

    closeConsumer(consumerId) {
        const consumer = this.consumers.get(consumerId);
        if (!consumer) return;

        const socket = socketInstance.getSocket();
        socket.emit('consumerClosed', { consumerId: consumer.id }, () => {
            consumer.close();

            this.consumers.delete(consumerId);
            this.mediaStreams.delete(consumer.producerId);
            this.emit('removeMediaStream', { id: consumer.producerId });
        });
    }

    async produce(source, track) {
        if (!this.sendTransport || !track || track.readyState !== 'live') {
            console.error(`Producer oluşturulamadı: Track ${source} geçerli değil.`);
            return null;
        }

        try {
            const producer = await this.sendTransport.produce({ track, appData: { source } });

            producer.on('transportclose', () => {
                console.warn(`Producer ${source} transport kapandı, temizleniyor.`);
                this.producers.delete(source);
            });

            producer.on('close', () => {
                console.warn(`Producer ${source} kapandı, temizleniyor.`);
                this.producers.delete(source);
            });

            this.producers.set(source, producer);

            return producer;
        } catch (error) {
            console.error('Producer oluşturulamadı:', error);
        }

        return null;
    }

    closeProducer(source) {
        const producer = this.producers.get(source);
        if (!producer) return;

        const socket = socketInstance.getSocket();
        socket.emit('producerClosed', { producerId: producer.id }, () => {
            producer.close();

            this.producers.delete(source);
        });
    }

    getMediaStreams() {
        return this.mediaStreams;
    }

    stopConsumers() {
        this.consumers.forEach((consumer, consumerId) => {
            if (consumer.appData.source !== 'userAudio') {
                this.closeConsumer(consumerId);
            }
        });
    }
}

const mediasoupInstance = new MediasoupService();

export default mediasoupInstance;