import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CreateRoom = () => {
    const navigate = useNavigate();

    const {
        sessionInfo
    } = useSelector((state) => state.session);

    useEffect(() => {
        console.log('CreateRoom: componentDidMount()');

        return () => {
            console.log('CreateRoom: componentWillUnmount()');
        };
    }, []);

    return (
        <div className="middle-box text-center w-100">
            <div className="m-md">
                <p>Hızlıca bir oda oluşturup arkadaşlarınla sesli ve görüntülü görüşebilirsin.</p><br />
                <button className="btn btn-success" onClick={() => navigate(`/${sessionInfo.username || sessionInfo.nickname}`)} type="button"><i className="fa fa-plus m-r-xs"></i>Yeni Oda Oluştur</button>
            </div>
        </div>
    );
};

export default CreateRoom;