const domainResolver = () => {
    const parts = document.location.hostname.split('.');
    return parts.length >= 2 ? `.${parts.slice(-2).join('.')}` : 'localhost';
};

const getCookie = (cname, defaultValue) => {
    try {
        const cookies = document.cookie
            .split(';')
            .map((c) => c.trim())
            .map((c) => c.split('='))
            .reduce((acc, [key, value]) => {
                acc[key] = decodeURIComponent(value || '');
                return acc;
            }, {});

        return cookies[cname] || defaultValue;
    } catch (error) {
        console.error('getCookie error:', error);
        return defaultValue;
    }
};

const setCookie = (cname, cvalue, expires = 0) => {
    const domain = domainResolver();

    let cookieString = `${cname}=${encodeURIComponent(cvalue)}; path=/; domain=${domain}; Secure; SameSite=Lax`;

    if (expires > 0) {
        cookieString += `; expires=${new Date(Date.now() + expires * 1000).toUTCString()}`;
    }

    document.cookie = cookieString;

    return true;
};

const removeCookie = (cname) => {
    const domain = domainResolver();

    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Max-Age=0; path=/; domain=${domain}; Secure; SameSite=Lax`;

    return true;
};

module.exports = {
    getCookie,
    setCookie,
    removeCookie
};