import { SoundManagerProvider } from './SoundManager';

const Providers = ({ children }) => {
    return (
        <SoundManagerProvider>
            {children}
        </SoundManagerProvider>
    );
};

export default Providers;