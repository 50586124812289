import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from 'react-modal';
import swal from 'sweetalert';

import config from '../../app/config';
import { setCookie } from '../../common/utils/cookies';

import {
    initializeSession
} from '../../app/slices/sessionSlice';

const LoginModal = (props) => {
    const dispatch = useDispatch();
    const usernameRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        let validationMessage = '';

        if (!username) {
            validationMessage = 'Kullanıcı Adı veya E-Posta adresi boş bırakılamaz.';
        } else if (!password) {
            validationMessage = 'Şifre boş bırakılamaz.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username,
                password
            })
        };

        fetch(`${config.restApi}login`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        setCookie('sessionHash', responseData.userInfo.sessionHash, 0);
                        setCookie('sessionType', 'user', 0);

                        dispatch(initializeSession({ sessionInfo: responseData.userInfo, sessionType: 'user' }));
                        break;
                    case 203:
                        swal({
                            dangerMode: true,
                            icon: 'info',
                            title: 'Eksik Bilgi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 403:
                    case 404:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [dispatch, username, password]);

    useEffect(() => {
        console.log('LoginModal: componentDidMount()');

        return () => {
            console.log('LoginModal: componentWillUnmount()');
        };
    }, []);

    return (
        <Modal
            isOpen={true}
            onAfterOpen={() => usernameRef.current.focus()}
            onRequestClose={props.cancel}
            style={{
                content: {
                    maxWidth: 640,
                    overflow: null,
                    padding: null,
                    position: null,
                    width: '95%'
                },
                overlay: {
                    position: 'fixed',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    overflowY: 'auto',
                    zIndex: 2500
                }
            }}
        >
            <form onSubmit={onSubmit}>
                <div className={isLoading ? "inmodal ibox-content modal-content no-padding sk-loading" : "inmodal ibox-content modal-content no-padding"}>
                    <div className="sk-spinner sk-spinner-fading-circle">
                        <div className="sk-circle1 sk-circle"></div>
                        <div className="sk-circle2 sk-circle"></div>
                        <div className="sk-circle3 sk-circle"></div>
                        <div className="sk-circle4 sk-circle"></div>
                        <div className="sk-circle5 sk-circle"></div>
                        <div className="sk-circle6 sk-circle"></div>
                        <div className="sk-circle7 sk-circle"></div>
                        <div className="sk-circle8 sk-circle"></div>
                        <div className="sk-circle9 sk-circle"></div>
                        <div className="sk-circle10 sk-circle"></div>
                        <div className="sk-circle11 sk-circle"></div>
                        <div className="sk-circle12 sk-circle"></div>
                    </div>
                    <div className="modal-header">
                        <button className="close" onClick={props.cancel} type="button"><span aria-hidden="true">&times;</span><span className="sr-only">Kapat</span></button>
                        <i className="fa fa-lock modal-icon"></i>
                        <h4 className="modal-title">Giriş Yap</h4>
                        <small className="font-bold">Tüm özelliklerden faydalanmak için giriş yap.</small>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Kullanıcı Adı veya E-Posta</label>
                            <input className="form-control" onChange={(event) => setUsername(event.target.value)} placeholder="Kullanıcı Adı veya E-Posta" ref={usernameRef} type="text" value={username} />
                        </div>
                        <div className="form-group">
                            <label>Şifreniz</label>
                            <input className="form-control" onChange={(event) => setPassword(event.target.value)} placeholder="Şifreniz" type="password" value={password} />
                        </div>
                        <div className="form-group m-t-lg">
                            <Link to="/forgotPassword" className="text-primary">Şifremi Unuttum</Link>
                        </div>
                        <div className="form-group">
                            <Link to="/register" className="text-primary">Kayıt Ol</Link>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-white" onClick={props.cancel} type="button"><i className="fa fa-times m-r-xs"></i>Kapat</button>
                        <button className="btn btn-primary" type="submit"><i className="fa fa-sign-in m-r-xs"></i>Giriş Yap</button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default LoginModal;