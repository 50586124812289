import { useEffect } from 'react';

const Messages = () => {
    useEffect(() => {
        console.log('Messages: componentDidMount()');

        return () => {
            console.log('Messages: componentWillUnmount()');
        };
    }, []);

    return (
        <span>Messages</span>
    );
};

export default Messages;