import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../app/config';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { setCookie } from '../../common/utils/cookies';

import {
    initializeSession
} from '../../app/slices/sessionSlice';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        const validationHash = searchParams.get('validationHash');
        let validationMessage = '';

        if (!validationHash) {
            validationMessage = 'Doğrulama kodu boş bırakılamaz.';
        } else if (!password) {
            validationMessage = 'Yeni şifreniz boş bırakılamaz.';
        } else if (!passwordRepeat) {
            validationMessage = 'Yeni şifrenizin tekrarı boş bırakılamaz.';
        } else if (password !== passwordRepeat) {
            validationMessage = 'Yeni şifre ile yeni şifre tekrarı eşleşmiyor.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                password,
                validationHash
            })
        };

        fetch(`${config.restApi}resetPassword`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        setCookie('sessionHash', responseData.userInfo.sessionHash, 126230400);
                        setCookie('sessionType', 'user', 126230400);

                        dispatch(initializeSession({ sessionInfo: responseData.userInfo, sessionType: 'user' }));
                        break;
                    case 203:
                        swal({
                            dangerMode: true,
                            icon: 'info',
                            title: 'Eksik Bilgi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 205:
                    case 403:
                    case 404:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [dispatch, searchParams, password, passwordRepeat]);

    useEffect(() => {
        console.log('ResetPassword: componentDidMount()');

        return () => {
            console.log('ResetPassword: componentWillUnmount()');
        };
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="gray-bg w-100 h-100">
            <div className="passwordBox">
                <div className="ibox">
                    <div className="ibox-content">
                        <h2 className="font-bold">Merhaba</h2>
                        <p>Aşağıdaki formu kullanarak şifreni değiştirebilirsin.</p>
                        <div className="row">
                            <div className="col-md-12">
                                <form className="m-t" onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <input autoComplete="off" className="form-control" onChange={(event) => setPassword(event.target.value)} placeholder="Yeni Şifreniz" required={true} type="password" value={password} />
                                    </div>
                                    <div className="form-group">
                                        <input autoComplete="off" className="form-control" onChange={(event) => setPasswordRepeat(event.target.value)} placeholder="Yeni Şifreniz Tekrar" required={true} type="password" value={passwordRepeat} />
                                    </div>
                                    <button className="btn btn-primary block full-width m-b" type="submit">Şifremi Değiştir</button>
                                </form>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 text-center"><small>qwerup.com &copy; 2020 - 2025</small></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;