import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import swal from 'sweetalert';

import mediasoupInstance from '../../app/services/mediasoup';
import socketInstance from '../../app/services/socket';

import {
    setCurrentRoomStatus,
    clearRoom,
    setParticipants,
    setProducers
} from '../../app/slices/roomSlice';

const CurrentConnection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const playerRefs = useRef({});

    const {
        participantAudioSettings
    } = useSelector((state) => state.audio);

    const {
        currentRoom,
        currentRoomPassword,
        producers
    } = useSelector((state) => state.room);

    const {
        connectionStatus
    } = useSelector((state) => state.session);

    const [mediaStreams, setMediaStreams] = useState([]);

    const leaveRoom = useCallback(() => {
        mediasoupInstance.stopDesktop();
        mediasoupInstance.stopUserAudio();
        mediasoupInstance.stopUserVideo();

        const socket = socketInstance.getSocket();
        socket.connected && socket.emit('leaveRoom', {}, () => { });

        dispatch(clearRoom());
    }, [dispatch]);

    useEffect(() => {
        console.log('CurrentConnection: componentDidMount()');

        return () => {
            console.log('CurrentConnection: componentWillUnmount()');
            console.log('MediasoupInstance: destroy()');

            dispatch(clearRoom());

            mediasoupInstance.destroy();
        };
    }, [dispatch]);

    useEffect(() => {
        switch (connectionStatus) {
            case 'connected':
                const socket = socketInstance.getSocket();
                socket.emit('joinExistingRoom', { room: currentRoom, password: currentRoomPassword }, response => {
                    switch (response.status) {
                        case 200:
                            console.log('MediasoupInstance: init()');

                            mediasoupInstance.init().then(async () => {
                                mediasoupInstance.on('addMediaStream', ({ id, peer, source, src }) => source === 'userAudio' && setMediaStreams(prevStreams => prevStreams.some(prevStream => prevStream.id === id) ? prevStreams : [...prevStreams, { id, peer, source, src }]));
                                mediasoupInstance.on('removeMediaStream', ({ id }) => setMediaStreams(prevStreams => prevStreams.filter(stream => stream.id !== id)));

                                const participantResponse = await socket.request('getParticipants', {});
                                if (participantResponse?.status === 200) {
                                    dispatch(setParticipants(participantResponse.participants));
                                }

                                const producerResponse = await socket.request('getProducers', {});
                                if (producerResponse?.status === 200) {
                                    dispatch(setProducers(producerResponse.producers));
                                }

                                dispatch(setCurrentRoomStatus('connected'));

                                await mediasoupInstance.reconnect();
                            });
                            break;
                        case 203:
                        case 404:
                            swal({
                                dangerMode: true,
                                icon: 'warning',
                                title: 'Hay Aksi',
                                text: response.message,
                                buttons: 'Tamam'
                            }).then(() => leaveRoom());
                            break;
                        default:
                            console.log(response);
                            break;
                    }
                });
                break;
            case 'disconnected':
                console.log('MediasoupInstance: destroy()');

                dispatch(setCurrentRoomStatus('disconnected'));
                dispatch(setParticipants([]));
                dispatch(setProducers([]));

                mediasoupInstance.destroy();
                break;
            default:
                console.log(connectionStatus);
                break;
        }
    }, [dispatch, leaveRoom, connectionStatus, currentRoom, currentRoomPassword]);

    useEffect(() => {
        for (const [participantTag, audioSettings] of Object.entries(participantAudioSettings)) {
            for (const [source, volume] of Object.entries(audioSettings)) {
                const player = playerRefs.current[`${participantTag}_${source}`];
                if (player) {
                    const newVolume = volume / 100;
                    if (player.volume !== newVolume) {
                        player.volume = newVolume;
                    }
                }
            }
        }
    }, [participantAudioSettings]);

    useEffect(() => {
        const producerIds = new Set([...producers.map(producer => producer.id), 'desktopAudio', 'desktopVideo', 'userAudio', 'userVideo']);

        setMediaStreams(prevMediaStreams => {
            const prevMediaStreamIds = new Set(prevMediaStreams.map(prevMediaStream => prevMediaStream.id));

            producers.forEach(producer => {
                if (!prevMediaStreamIds.has(producer.id) && producer.appData.source === 'userAudio') {
                    mediasoupInstance.consume(producer);
                }
            });

            const updatedMediaStreams = prevMediaStreams.filter(prevMediaStream => producerIds.has(prevMediaStream.id));

            if (updatedMediaStreams.length === prevMediaStreams.length && updatedMediaStreams.every((updatedMediaStream, index) => updatedMediaStream.id === prevMediaStreams[index].id)) {
                return prevMediaStreams;
            }

            return updatedMediaStreams;
        });
    }, [producers]);

    return (
        <li align="center">
            <button className="btn btn-sm btn-primary m-b-sm" onClick={() => navigate(`/${currentRoom}`)} title={`#${currentRoom} odasına bağlısınız`}>
                <span className="nav-label m-r-xs">#<strong>{currentRoom}</strong> bağlanıldı</span>
                <i className="fa fa-arrow-circle-right"></i>
            </button>
            <br />
            <button className="btn btn-sm btn-danger" onClick={() => leaveRoom()} title={'Odadan ayrıl'}>
                <span className="nav-label m-r-xs">Odadan ayrıl</span>
                <i className="fa fa-times-circle"></i>
            </button>
            {mediaStreams.map(mediaStream =>
                <audio
                    autoPlay
                    key={mediaStream.id}
                    muted={mediaStream.id === mediaStream.source}
                    playsInline
                    ref={async (element) => {
                        if (element && !element.srcObject) {
                            element.srcObject = mediaStream.src;

                            if ('setSinkId' in HTMLMediaElement.prototype) {
                                element.setSinkId(await mediasoupInstance.getSelectedDeviceId('audiooutput'));
                            }

                            if (mediaStream.peer) {
                                element.volume = (participantAudioSettings[mediaStream.peer.appData.tag]?.[mediaStream.source] ?? 100) / 100;
                                playerRefs.current[`${mediaStream.peer.appData.tag}_${mediaStream.source}`] = element;
                            }
                        }
                    }}
                    webkit-playsinline="true"
                />
            )}
        </li>
    );
};

export default CurrentConnection;