const LoadingSpinner = () => (
    <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
            {[...Array(12)].map((_, index) => (
                <div key={index} className={`sk-circle${index + 1} sk-circle`}></div>
            ))}
        </div>
    </div>
);

export default LoadingSpinner;