import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isFocused: document.hasFocus()
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setFocus: (state, action) => {
            state.isFocused = action.payload;
        }
    }
});

export const {
    setFocus
} = appSlice.actions;

export default appSlice.reducer;