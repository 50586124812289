import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from 'react-modal';
import swal from 'sweetalert';

import config from '../../app/config';

const ChangePasswordModal = (props) => {
    const { sessionInfo } = useSelector((state) => state.session);
    const currentPasswordRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        let validationMessage = '';

        if (!currentPassword) {
            validationMessage = 'Geçerli şifreniz boş bırakılamaz.';
        } else if (!password) {
            validationMessage = 'Yeni şifreniz boş bırakılamaz.';
        } else if (!passwordRepeat) {
            validationMessage = 'Yeni şifrenizin tekrarı boş bırakılamaz.';
        } else if (password !== passwordRepeat) {
            validationMessage = 'Yeni şifre ile yeni şifre tekrarı eşleşmiyor.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                currentPassword,
                password
            })
        };

        fetch(`${config.restApi}changePassword`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        swal({
                            dangerMode: false,
                            icon: 'success',
                            title: 'İşlem tamamlandı',
                            text: 'Şifreniz başarıyla güncellendi.',
                            buttons: 'Tamam'
                        }).then(() => props?.success());
                        break;
                    case 203:
                        swal({
                            dangerMode: true,
                            icon: 'info',
                            title: 'Eksik Bilgi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 403:
                    case 404:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [props, sessionInfo, currentPassword, password, passwordRepeat]);

    useEffect(() => {
        console.log('ChangePasswordModal: componentDidMount()');

        return () => {
            console.log('ChangePasswordModal: componentWillUnmount()');
        };
    }, []);

    return (
        <Modal
            isOpen={true}
            onAfterOpen={() => currentPasswordRef.current.focus()}
            onRequestClose={props.cancel}
            style={{
                content: {
                    maxWidth: 640,
                    overflow: null,
                    padding: null,
                    position: null,
                    width: '95%'
                },
                overlay: {
                    position: 'fixed',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    overflowY: 'auto',
                    zIndex: 2500
                }
            }}
        >
            <form onSubmit={onSubmit}>
                <div className={isLoading ? "inmodal ibox-content modal-content no-padding sk-loading" : "inmodal ibox-content modal-content no-padding"}>
                    <div className="sk-spinner sk-spinner-fading-circle">
                        <div className="sk-circle1 sk-circle"></div>
                        <div className="sk-circle2 sk-circle"></div>
                        <div className="sk-circle3 sk-circle"></div>
                        <div className="sk-circle4 sk-circle"></div>
                        <div className="sk-circle5 sk-circle"></div>
                        <div className="sk-circle6 sk-circle"></div>
                        <div className="sk-circle7 sk-circle"></div>
                        <div className="sk-circle8 sk-circle"></div>
                        <div className="sk-circle9 sk-circle"></div>
                        <div className="sk-circle10 sk-circle"></div>
                        <div className="sk-circle11 sk-circle"></div>
                        <div className="sk-circle12 sk-circle"></div>
                    </div>
                    <div className="modal-header">
                        <button className="close" onClick={props.cancel} type="button"><span aria-hidden="true">&times;</span><span className="sr-only">Kapat</span></button>
                        <i className="fa fa-lock modal-icon"></i>
                        <h4 className="modal-title">Şifremi Değiştir</h4>
                        <small className="font-bold">Şifrenizi değiştirmek için geçerli şifrenizi girmeniz gerekmektedir.</small>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Geçerli Şifreniz</label>
                            <input className="form-control" onChange={(event) => setCurrentPassword(event.target.value)} placeholder="Geçerli Şifreniz" ref={currentPasswordRef} type="password" value={currentPassword} />
                        </div>
                        <div className="form-group">
                            <label>Yeni Şifreniz</label>
                            <input className="form-control" onChange={(event) => setPassword(event.target.value)} placeholder="Yeni Şifreniz" type="password" value={password} />
                        </div>
                        <div className="form-group">
                            <label>Yeni Şifreniz (Tekrar)</label>
                            <input className="form-control" onChange={(event) => setPasswordRepeat(event.target.value)} placeholder="Yeni Şifreniz (Tekrar)" type="password" value={passwordRepeat} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-white" onClick={props.cancel} type="button"><i className="fa fa-times m-r-xs"></i>Kapat</button>
                        <button className="btn btn-primary" type="submit"><i className="fa fa-save m-r-xs"></i>Şifremi Değiştir</button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default ChangePasswordModal;