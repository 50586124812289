import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentRoom: '',
    currentRoomPassword: '',
    currentRoomStatus: 'disconnected',
    participants: [],
    producers: [],
    messages: []
};

const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        initializeRoom: (state, action) => {
            const { room, password } = action.payload;
            state.currentRoom = room;
            state.currentRoomPassword = password;
        },
        setCurrentRoom: (state, action) => {
            state.currentRoom = action.payload;
        },
        setCurrentRoomPassword: (state, action) => {
            state.currentRoomPassword = action.payload;
        },
        setCurrentRoomStatus: (state, action) => {
            state.currentRoomStatus = action.payload;
        },
        clearRoom: (state) => {
            state.currentRoom = '';
            state.currentRoomPassword = '';
            state.currentRoomStatus = 'disconnected';
            state.participants = [];
            state.producers = [];
            state.messages = [];
        },
        setParticipants: (state, action) => {
            state.participants = action.payload;
        },
        addParticipant: (state, action) => {
            state.participants.push(action.payload);
        },
        removeParticipant: (state, action) => {
            state.participants = state.participants.filter(participant => participant.id !== action.payload.id);
        },
        setProducers: (state, action) => {
            state.producers = action.payload;
        },
        addProducer: (state, action) => {
            state.producers.push(action.payload);
        },
        removeProducer: (state, action) => {
            state.producers = state.producers.filter(producer => producer.id !== action.payload.id);
        },
        addMessage: (state, action) => {
            state.messages.push(action.payload);
        }
    }
});

export const {
    initializeRoom,
    setCurrentRoom,
    setCurrentRoomPassword,
    setCurrentRoomStatus,
    clearRoom,
    setParticipants,
    addParticipant,
    removeParticipant,
    setProducers,
    addProducer,
    removeProducer,
    addMessage
} = roomSlice.actions;

export default roomSlice.reducer;