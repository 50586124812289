import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../app/config';
import LoadingSpinner from '../../common/components/LoadingSpinner';

const MagicLink = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        let validationMessage = '';

        if (!email) {
            validationMessage = 'E-Posta adresi boş bırakılamaz.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email
            })
        };

        fetch(`${config.restApi}magicLink`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        swal({
                            dangerMode: false,
                            icon: 'success',
                            title: 'Tamamlandı',
                            text: 'Giriş bilgileri E-Posta adresinize gönderildi.',
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 203:
                        swal({
                            dangerMode: true,
                            icon: 'info',
                            title: 'Eksik Bilgi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 403:
                    case 404:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [email]);

    useEffect(() => {
        console.log('MagicLink: componentDidMount()');

        return () => {
            console.log('MagicLink: componentWillUnmount()');
        };
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="gray-bg w-100 h-100">
            <div className="passwordBox">
                <div className="ibox">
                    <div className="ibox-content">
                        <h2 className="font-bold">Sihirli Giriş</h2>
                        <p>Sihirli bağlantı ile giriş için e-posta adresinize ihtiyacımız var.</p>
                        <div className="row">
                            <div className="col-md-12">
                                <form className="m-t" onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <input className="form-control" onChange={(event) => setEmail(event.target.value)} placeholder="E-Posta adresiniz" required={true} type="email" value={email} />
                                    </div>
                                    <button className="btn btn-primary block full-width m-b" type="submit"><i className="fa fa-send"></i> Giriş Bilgilerimi Gönder</button>
                                    <Link className="btn btn-default full-width mb-2" to="/login"><i className="fa fa-keyboard-o"></i> Şifre ile Giriş Yap</Link>
                                </form>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 text-center"><small>qwerup.com &copy; 2020 - 2025</small></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MagicLink;