import { useEffect } from 'react';

const Settings = () => {
    useEffect(() => {
        console.log('Settings: componentDidMount()');

        return () => {
            console.log('Settings: componentWillUnmount()');
        };
    }, []);

    return (
        <span>Settings</span>
    );
};

export default Settings;