import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../app/config';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { setCookie } from '../../common/utils/cookies';
import Images from '../../common/utils/images';

import {
    initializeSession
} from '../../app/slices/sessionSlice';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { sessionType } = useSelector((state) => state.session);

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const guestLogin = useCallback(() => {
        if (sessionType) {
            navigate('/');
            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${config.restApi}guestLogin`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        setCookie('sessionHash', responseData.guestInfo.sessionHash, 126230400);
                        setCookie('sessionType', 'guest', 126230400);

                        dispatch(initializeSession({ sessionInfo: responseData.guestInfo, sessionType: 'guest' }));
                        navigate('/');
                        break;
                    case 403:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [dispatch, navigate, sessionType]);

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        let validationMessage = '';

        if (!username) {
            validationMessage = 'Kullanıcı Adı veya E-Posta adresi boş bırakılamaz.';
        } else if (!password) {
            validationMessage = 'Şifre boş bırakılamaz.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username,
                password
            })
        };

        fetch(`${config.restApi}login`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        setCookie('sessionHash', responseData.userInfo.sessionHash, (rememberMe && 126230400) || 0);
                        setCookie('sessionType', 'user', (rememberMe && 126230400) || 0);

                        dispatch(initializeSession({ sessionInfo: responseData.userInfo, sessionType: 'user' }));
                        break;
                    case 203:
                        swal({
                            dangerMode: true,
                            icon: 'info',
                            title: 'Eksik Bilgi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 403:
                    case 404:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [dispatch, username, password, rememberMe]);

    useEffect(() => {
        console.log('Login: componentDidMount()');

        return () => {
            console.log('Login: componentWillUnmount()');
        };
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="gray-bg w-100 h-100">
            <div className="middle-box text-center loginscreen">
                <img alt="qwerup" src={Images.logo} width="64" height="64" /><br /><br />
                <h3>Merhaba!</h3>
                <p>qwerup'a hoşgeldin.</p>
                <p>Tüm özelliklerden faydalanmak için giriş yap</p>
                <form className="m-t" onSubmit={onSubmit}>
                    <div className="form-group">
                        <input className="form-control" onChange={(event) => setUsername(event.target.value)} placeholder="Kullanıcı Adı veya E-Posta" required={true} type="text" value={username} />
                    </div>
                    <div className="form-group">
                        <input autoComplete="off" className="form-control" onChange={(event) => setPassword(event.target.value)} placeholder="Şifre" required={true} type="password" value={password} />
                    </div>
                    <div className="form-group">
                        <label><input checked={rememberMe} onChange={(event) => setRememberMe(event.target.checked)} type="checkbox" /> Oturumumu her zaman açık tut</label>
                    </div>
                    <button className="btn btn-primary block full-width m-b" type="submit"><i className="fa fa-sign-in"></i> Giriş Yap</button>
                    <Link className="btn btn-default full-width mb-2" to="/magicLink"><i className="fa fa-link"></i> Sihirli Bağlantı ile Giriş Yap</Link>
                    <Link to="/forgotPassword"><small>Şifremi unuttum</small></Link>
                    <p className="text-muted text-center"><small>Bir hesabınız yok mu?</small></p>
                    <Link className="btn btn-sm btn-white btn-block m-b" to="/register"><i className="fa fa-user"></i> Kayıt Ol</Link>
                    <p className="text-muted text-center"><small>- veya -</small></p>
                </form>
                <button className="btn btn-sm btn-success btn-block" onClick={guestLogin}>Giriş Yapmadan Devam Et <i className="fa fa-chevron-right"></i></button>
                <p className="m-t"><small>qwerup.com &copy; 2020 - 2025</small></p>
                <p className="m-t">Yardım ve destek için Instagram sayfamızı ziyaret edebilir, gelişmelerden haberdar olabilirsiniz.</p>
                <a className="btn btn-default" href="https://www.instagram.com/qwerup.official/" rel="noreferrer" target="_blank"><i className="fa fa-instagram m-r-xs"></i><b>Instagram</b> sayfamızı takip edin.</a>
            </div>
        </div>
    );
};

export default Login;