const hostResolver = () => {
    const parts = document.location.hostname.split('.');
    return parts.length >= 2 ? `${parts.slice(-2).join('.')}` : 'localhost';
};

const host = hostResolver();

const config = {
    host,
    restApi: `https://${host}:8443/v1.0/`
};

export default config;