import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from 'react-modal';
import swal from 'sweetalert';

import config from '../../app/config';

const AddFriendModal = (props) => {
    const { sessionInfo } = useSelector((state) => state.session);
    const usernameRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        let validationMessage = '';

        if (!username) {
            validationMessage = 'Lütfen Kullanıcı Adı veya E-Posta girin.';
        }

        if (validationMessage) {
            swal({
                dangerMode: true,
                icon: 'info',
                title: 'Eksik Bilgi',
                text: validationMessage,
                buttons: 'Tamam'
            });

            return;
        }

        setIsLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username
            })
        };

        fetch(`${config.restApi}user/friend`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        swal({
                            dangerMode: false,
                            icon: 'success',
                            title: 'İşlem tamamlandı',
                            text: 'Arkadaşlık isteğiniz başarıyla gönderildi.',
                            buttons: 'Tamam'
                        }).then(() => props?.success());
                        break;
                    case 400:
                        break;
                    case 205:
                    case 403:
                    case 404:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [props, sessionInfo, username]);

    useEffect(() => {
        console.log('AddFriendModal: componentDidMount()');

        return () => {
            console.log('AddFriendModal: componentWillUnmount()');
        };
    }, []);

    return (
        <Modal
            isOpen={true}
            onAfterOpen={() => usernameRef.current.focus()}
            onRequestClose={props.cancel}
            style={{
                content: {
                    maxWidth: 640,
                    overflow: null,
                    padding: null,
                    position: null,
                    width: '95%'
                },
                overlay: {
                    position: 'fixed',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    overflowY: 'auto',
                    zIndex: 2500
                }
            }}
        >
            <form onSubmit={onSubmit}>
                <div className={isLoading ? "inmodal ibox-content modal-content no-padding sk-loading" : "inmodal ibox-content modal-content no-padding"}>
                    <div className="sk-spinner sk-spinner-fading-circle">
                        <div className="sk-circle1 sk-circle"></div>
                        <div className="sk-circle2 sk-circle"></div>
                        <div className="sk-circle3 sk-circle"></div>
                        <div className="sk-circle4 sk-circle"></div>
                        <div className="sk-circle5 sk-circle"></div>
                        <div className="sk-circle6 sk-circle"></div>
                        <div className="sk-circle7 sk-circle"></div>
                        <div className="sk-circle8 sk-circle"></div>
                        <div className="sk-circle9 sk-circle"></div>
                        <div className="sk-circle10 sk-circle"></div>
                        <div className="sk-circle11 sk-circle"></div>
                        <div className="sk-circle12 sk-circle"></div>
                    </div>
                    <div className="modal-header">
                        <button className="close" onClick={props.cancel} type="button"><span aria-hidden="true">&times;</span><span className="sr-only">Kapat</span></button>
                        <i className="fa fa-user modal-icon"></i>
                        <h4 className="modal-title">Arkadaş Ekle</h4>
                        <small className="font-bold">Arkadaşını <strong>Kullanıcı Adı</strong> veya <strong>E-Posta Adresi</strong> ile ekleyebilirsin.</small>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label><strong>Kullanıcı Adı</strong> veya <strong>E-Posta</strong> girin.</label>
                            <input className="form-control" onChange={(event) => setUsername(event.target.value)} placeholder="Kullanıcı Adı veya E-Posta" ref={usernameRef} type="username" value={username} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-white" onClick={props.cancel} type="button"><i className="fa fa-times m-r-xs"></i>Kapat</button>
                        <button className="btn btn-primary" type="submit"><i className="fa fa-plus m-r-xs"></i>Arkadaşlık İsteği Gönder</button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default AddFriendModal;