import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import mediasoupInstance from '../../app/services/mediasoup';
import soundManagerInstance from '../../app/services/soundManager';

import Modal from 'react-modal';

const DeviceSelectorModal = (props) => {
    const [devices, setDevices] = useState({ audioInput: [], audioOutput: [], videoInput: [] });
    const [noiseSuppressionEnabled, setNoiseSuppressionEnabled] = useState(localStorage.getItem('noiseSuppression') === 'true');
    const [selectedDevices, setSelectedDevices] = useState({ audioInput: '', audioOutput: '', videoInput: '' });
    const [permissions, setPermissions] = useState({ camera: null, microphone: null });

    const {
        camera,
        microphone
    } = useSelector((state) => state.mediaStream);

    const handleNoiseSuppressionChange = (event) => {
        const isEnabled = event.target.value === 'true';
        setNoiseSuppressionEnabled(isEnabled);
        localStorage.setItem('noiseSuppression', isEnabled);
        if (microphone) {
            mediasoupInstance.stopUserAudio();
            mediasoupInstance.startUserAudio();
        }
    };

    const loadDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();

            const audioInput = devices.filter(device => device.kind === 'audioinput');
            const audioOutput = devices.filter(device => device.kind === 'audiooutput');
            const videoInput = devices.filter(device => device.kind === 'videoinput');

            let savedDevices = {};

            try {
                savedDevices = JSON.parse(localStorage.getItem('selectedDevices')) || {};
            } catch (error) {
                localStorage.removeItem('selectedDevices');
            }

            setDevices({ audioInput, audioOutput, videoInput });

            setSelectedDevices({
                audioInput: savedDevices.audioInput && audioInput.some(device => (device.label === savedDevices.audioInput || device.groupId === savedDevices.audioInput || device.deviceId === savedDevices.audioInput)) ? savedDevices.audioInput : (audioInput.length > 0 ? (audioInput[0].label || audioInput[0].groupId || audioInput[0].deviceId) : ''),
                audioOutput: savedDevices.audioOutput && audioOutput.some(device => (device.label === savedDevices.audioOutput || device.groupId === savedDevices.audioOutput || device.deviceId === savedDevices.audioOutput)) ? savedDevices.audioOutput : (audioOutput.length > 0 ? (audioOutput[0].label || audioOutput[0].groupId || audioOutput[0].deviceId) : ''),
                videoInput: savedDevices.videoInput && videoInput.some(device => (device.label === savedDevices.videoInput || device.groupId === savedDevices.videoInput || device.deviceId === savedDevices.videoInput)) ? savedDevices.videoInput : (videoInput.length > 0 ? (videoInput[0].label || videoInput[0].groupId || videoInput[0].deviceId) : '')
            });
        } catch (error) {
            console.error('Cihazları yüklerken hata oluştu:', error);
        }
    };

    const saveDevices = async (deviceType, deviceKey) => {
        setSelectedDevices(prev => {
            const updatedDevices = { ...prev, [deviceType]: deviceKey };
            localStorage.setItem('selectedDevices', JSON.stringify(updatedDevices));
            return updatedDevices;
        });

        switch (deviceType) {
            case 'videoInput':
                if (camera) {
                    mediasoupInstance.stopUserVideo();
                    const deviceId = await mediasoupInstance.findDeviceId('videoinput', deviceKey);
                    mediasoupInstance.startUserVideo(deviceId);
                }
                break;
            case 'audioInput':
                if (microphone) {
                    mediasoupInstance.stopUserAudio();
                    const deviceId = await mediasoupInstance.findDeviceId('audioinput', deviceKey);
                    mediasoupInstance.startUserAudio(deviceId);
                }
                break;
            case 'audioOutput':
                const deviceId = await mediasoupInstance.findDeviceId('audiooutput', deviceKey);

                for (const element of document.querySelectorAll('audio')) {
                    if (element.setSinkId) {
                        try {
                            await element.setSinkId(deviceId);
                            console.log(`Ses çıkışı değiştirildi: ${deviceId}`);
                        } catch (error) {
                            console.error(`Ses çıkış aygıtı değiştirilemedi: ${error}`);
                        }
                    }
                }

                await soundManagerInstance.setSinkId(deviceId);
                break;
            default:
                console.warn(`Bilinmeyen cihaz türü: ${deviceType}`);
        }
    };

    const checkPermissions = useCallback(async () => {
        try {
            let cameraPermissionStatus = null;
            let microphonePermissionStatus = null;

            const devices = await navigator.mediaDevices.enumerateDevices();
            const hasAudioInput = devices.some(device => device.kind === 'audioinput');
            const hasVideoInput = devices.some(device => device.kind === 'videoinput');

            if (!hasAudioInput && !hasVideoInput) {
                setPermissions({ camera: null, microphone: null });
                return;
            }

            if (navigator.permissions?.query) {
                if (hasAudioInput) {
                    const microphonePermission = await navigator.permissions.query({ name: 'microphone' }).catch(() => null);
                    microphonePermissionStatus = microphonePermission ? microphonePermission.state === 'granted' : null;
                }

                if (hasVideoInput) {
                    const cameraPermission = await navigator.permissions.query({ name: 'camera' }).catch(() => null);
                    cameraPermissionStatus = cameraPermission ? cameraPermission.state === 'granted' : null;
                }
            }

            const needToCheckAudio = hasAudioInput && microphonePermissionStatus === null;
            const needToCheckVideo = hasVideoInput && cameraPermissionStatus === null;

            if (needToCheckAudio || needToCheckVideo) {
                try {
                    const constraints = {
                        audio: needToCheckAudio,
                        video: needToCheckVideo
                    };

                    const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
                    mediaStream.getTracks().forEach(track => track.stop());

                    if (needToCheckAudio) microphonePermissionStatus = true;
                    if (needToCheckVideo) cameraPermissionStatus = true;
                } catch (error) {
                    if (needToCheckAudio) microphonePermissionStatus = false;
                    if (needToCheckVideo) cameraPermissionStatus = false;
                }
            }

            setPermissions({
                camera: cameraPermissionStatus,
                microphone: microphonePermissionStatus
            });

            loadDevices();
        } catch (error) {
            console.error('İzinleri sorgularken genel bir hata oluştu:', error);
        }
    }, []);

    const permissionRequest = useCallback(async () => {
        try {
            const constraints = {
                audio: permissions.microphone === false,
                video: permissions.camera === false
            };

            if (!constraints.audio && !constraints.video) return;

            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            mediaStream.getTracks().forEach(track => track.stop());

            setPermissions(prev => ({
                camera: constraints.video ? true : prev.camera,
                microphone: constraints.audio ? true : prev.microphone
            }));

            loadDevices();
        } catch (error) {
            console.error('İzin istenirken hata oluştu:', error);
        }
    }, [permissions]);

    useEffect(() => {
        console.log('DeviceSelectorModal: componentDidMount()');

        checkPermissions();

        return () => {
            console.log('DeviceSelectorModal: componentWillUnmount()');
        };
    }, [checkPermissions]);

    if (permissions.camera === false || permissions.microphone === false) {
        return (
            <Modal
                isOpen={true}
                onRequestClose={props.cancel}
                style={{
                    content: {
                        maxWidth: 640,
                        overflow: null,
                        padding: null,
                        position: null,
                        width: '95%'
                    },
                    overlay: {
                        position: 'fixed',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        overflowY: 'auto',
                        zIndex: 2500
                    }
                }}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Ses ve Görüntü Ayarları</h4>
                        <button className="close" onClick={props.cancel} type="button">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Devam edebilmek için <b>Kamera</b> ve <b>Mikrofon</b> izni gerekiyor.
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={permissionRequest} type="button">İzin ver</button>
                        <button className="btn btn-default" onClick={props.cancel} type="button">Vazgeç</button>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Modal
            isOpen={true}
            onRequestClose={props.cancel}
            style={{
                content: {
                    maxWidth: 640,
                    overflow: null,
                    padding: null,
                    position: null,
                    width: '95%'
                },
                overlay: {
                    position: 'fixed',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    overflowY: 'auto',
                    zIndex: 2500
                }
            }}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Ses ve Görüntü Ayarları</h4>
                    <button className="close" onClick={props.cancel} type="button">
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group row">
                        <h4>Ses Giriş Aygıtı</h4>
                        <select className="form-control" disabled={!permissions.microphone} value={selectedDevices.audioInput} onChange={event => saveDevices('audioInput', event.target.value)}>
                            {devices.audioInput.map(device => (
                                <option key={device.deviceId} value={device.label || device.groupId || device.deviceId}>
                                    {device.label || `Mikrofon ${device.deviceId}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group row">
                        <h4>Ses Çıkış Aygıtı</h4>
                        <select className="form-control" disabled={!permissions.microphone} value={selectedDevices.audioOutput} onChange={event => saveDevices('audioOutput', event.target.value)}>
                            {devices.audioOutput.map(device => (
                                <option key={device.deviceId} value={device.label || device.groupId || device.deviceId}>
                                    {device.label || `Hoparlör ${device.deviceId}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group row">
                        <h4>Video Giriş Aygıtı</h4>
                        <select className="form-control" disabled={!permissions.camera} value={selectedDevices.videoInput} onChange={event => saveDevices('videoInput', event.target.value)}>
                            {devices.videoInput.map(device => (
                                <option key={device.deviceId} value={device.label || device.groupId || device.deviceId}>
                                    {device.label || `Kamera ${device.deviceId}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group row">
                        <h4>Aktif Gürültü Engelleme</h4>
                        <select className="form-control" value={noiseSuppressionEnabled ? 'true' : 'false'} onChange={handleNoiseSuppressionChange}>
                            <option value="true">Açık</option>
                            <option value="false">Kapalı</option>
                        </select>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-primary" onClick={props.success} type="button">Tamam</button>
                </div>
            </div>
        </Modal>
    );
};

export default DeviceSelectorModal;