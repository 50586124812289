import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../app/config';
import AddFriendModal from '../../common/components/AddFriendModal';

const emptyStateMessage = {
    'all': 'Arkadaş listeniz boş',
    'blocked': 'Engellenen kişi bulunamadı',
    'online': 'Çevrimiçi arkadaşınız bulunamadı',
    'waiting': 'Bekleyen isteğiniz bulunamadı'
};

const presenceIcons = {
    'available': <i className="fa fa-circle m-r-sm text-navy" title="Çevrimiçi"></i>,
    'busy': <i className="fa fa-circle m-r-sm text-danger" title="Meşgul"></i>,
    'away': <i className="fa fa-circle m-r-sm text-warning" title="Dışarıda"></i>,
    'invisible': <i className="fa fa-circle m-r-sm text-muted" title="Görünmez"></i>,
    'offline': <i className="fa fa-circle m-r-sm text-muted" title="Çevrimdışı"></i>
};

const sectionTitle = {
    'all': 'Tümü',
    'blocked': 'Engellenen',
    'online': 'Çevrimiçi',
    'waiting': 'Bekleyen'
};

const Friends = () => {
    const navigate = useNavigate();
    const { section } = useParams();
    const { sessionInfo } = useSelector((state) => state.session);

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [viewState, setViewState] = useState({
        currentSection: '',
        emptyStateMessage: '',
        sectionTitle: '',
        users: []
    });

    const fetchUsers = useCallback(() => {
        setIsLoading(true);

        const path = { 'online': 'online', 'waiting': 'waiting', 'blocked': 'blocked' }[section] || 'friends';
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' }
        };

        fetch(`${config.restApi}users/${path}`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        setIsLoading(false);
                        setViewState({
                            currentSection: section,
                            emptyStateMessage: emptyStateMessage[section],
                            sectionTitle: sectionTitle[section],
                            users: responseData.users
                        });
                        break;
                    case 400:
                        break;
                    case 403:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [section, sessionInfo.sessionHash]);

    const updateUsers = useCallback((listType) => listType === section ? fetchUsers() : navigate(`/friends/${listType}`), [fetchUsers, navigate, section]);

    const accept = useCallback((userInfo) => {
        setIsLoading(true);

        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' }
        };

        fetch(`${config.restApi}user/friend/${userInfo.id}`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        swal({
                            dangerMode: false,
                            icon: 'success',
                            title: 'İşlem tamamlandı',
                            text: 'Arkadaşlık isteği kabul edildi.',
                            buttons: 'Tamam'
                        }).then(() => fetchUsers());
                        break;
                    case 400:
                        break;
                    case 403:
                    case 404:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Tamam'
                        }).then(() => setIsLoading(false));
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Tamam'
            }).then(() => setIsLoading(false)));
    }, [fetchUsers, sessionInfo.sessionHash]);

    const remove = useCallback((userInfo) => {
        swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Onayla',
            text: `${userInfo.username} adlı kullanıcıyı arkadaş listenizden kaldırmak istediğinize emin misiniz?`,
            buttons: { confirm: 'Tamam', cancel: 'Vazgeç' }
        }).then(value => {
            if (value) {
                setIsLoading(true);

                const requestOptions = {
                    method: 'DELETE',
                    headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' }
                };

                fetch(`${config.restApi}user/friend/${userInfo.id}`, requestOptions)
                    .then(response => response.json())
                    .then(responseData => {
                        switch (responseData.status) {
                            case 200:
                                swal({
                                    dangerMode: false,
                                    icon: 'success',
                                    title: 'İşlem tamamlandı',
                                    text: `${userInfo.username} arkadaş listenizden kaldırıldı.`,
                                    buttons: 'Tamam'
                                }).then(() => fetchUsers());
                                break;
                            case 400:
                                break;
                            case 403:
                            case 404:
                                swal({
                                    dangerMode: true,
                                    icon: 'warning',
                                    title: 'Hay Aksi',
                                    text: responseData.message,
                                    buttons: 'Tamam'
                                }).then(() => setIsLoading(false));
                                break;
                            case 500:
                                swal({
                                    dangerMode: true,
                                    icon: 'error',
                                    title: 'Hata',
                                    text: responseData.message,
                                    buttons: 'Tamam'
                                }).then(() => setIsLoading(false));
                                break;
                            default:
                                console.log(responseData);
                                break;
                        }
                    })
                    .catch(() => swal({
                        dangerMode: true,
                        icon: 'error',
                        title: 'Bağlantı Hatası',
                        text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                        buttons: 'Tamam'
                    }).then(() => setIsLoading(false)));
            }
        });
    }, [fetchUsers, sessionInfo.sessionHash]);

    const block = useCallback((userInfo) => {
        swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Onayla',
            text: `${userInfo.username} adlı kullanıcıyı engellemek istediğinize emin misiniz?`,
            buttons: { confirm: 'Tamam', cancel: 'Vazgeç' }
        }).then(value => {
            if (value) {
                setIsLoading(true);

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' }
                };

                fetch(`${config.restApi}user/block/${userInfo.id}`, requestOptions)
                    .then(response => response.json())
                    .then(responseData => {
                        switch (responseData.status) {
                            case 200:
                                swal({
                                    dangerMode: false,
                                    icon: 'success',
                                    title: 'İşlem tamamlandı',
                                    text: `${userInfo.username} başarıyla engellendi.`,
                                    buttons: 'Tamam'
                                }).then(() => fetchUsers());
                                break;
                            case 400:
                                break;
                            case 205:
                            case 403:
                            case 404:
                                swal({
                                    dangerMode: true,
                                    icon: 'warning',
                                    title: 'Hay Aksi',
                                    text: responseData.message,
                                    buttons: 'Tamam'
                                }).then(() => setIsLoading(false));
                                break;
                            case 500:
                                swal({
                                    dangerMode: true,
                                    icon: 'error',
                                    title: 'Hata',
                                    text: responseData.message,
                                    buttons: 'Tamam'
                                }).then(() => setIsLoading(false));
                                break;
                            default:
                                console.log(responseData);
                                break;
                        }
                    })
                    .catch(() => swal({
                        dangerMode: true,
                        icon: 'error',
                        title: 'Bağlantı Hatası',
                        text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                        buttons: 'Tamam'
                    }).then(() => setIsLoading(false)));
            }
        });
    }, [fetchUsers, sessionInfo.sessionHash]);

    const unblock = useCallback((userInfo) => {
        swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Onayla',
            text: `${userInfo.username} adlı kullanıcının engelini kaldırmak istediğinize emin misiniz?`,
            buttons: { confirm: 'Tamam', cancel: 'Vazgeç' }
        }).then(value => {
            if (value) {
                setIsLoading(true);

                const requestOptions = {
                    method: 'DELETE',
                    headers: { 'Authorization': `Bearer ${sessionInfo.sessionHash}`, 'Content-Type': 'application/json' }
                };

                fetch(`${config.restApi}user/block/${userInfo.id}`, requestOptions)
                    .then(response => response.json())
                    .then(responseData => {
                        switch (responseData.status) {
                            case 200:
                                swal({
                                    dangerMode: false,
                                    icon: 'success',
                                    title: 'İşlem tamamlandı',
                                    text: `${userInfo.username} adlı kullanıcının engelli başarıyla kaldırıldı.`,
                                    buttons: 'Tamam'
                                }).then(() => fetchUsers());
                                break;
                            case 400:
                                break;
                            case 205:
                            case 403:
                            case 404:
                                swal({
                                    dangerMode: true,
                                    icon: 'warning',
                                    title: 'Hay Aksi',
                                    text: responseData.message,
                                    buttons: 'Tamam'
                                }).then(() => setIsLoading(false));
                                break;
                            case 500:
                                swal({
                                    dangerMode: true,
                                    icon: 'error',
                                    title: 'Hata',
                                    text: responseData.message,
                                    buttons: 'Tamam'
                                }).then(() => setIsLoading(false));
                                break;
                            default:
                                console.log(responseData);
                                break;
                        }
                    })
                    .catch(() => swal({
                        dangerMode: true,
                        icon: 'error',
                        title: 'Bağlantı Hatası',
                        text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                        buttons: 'Tamam'
                    }).then(() => setIsLoading(false)));
            }
        });
    }, [fetchUsers, sessionInfo.sessionHash]);

    useEffect(() => {
        console.log(`Friends:${section || ''} componentDidMount()`);

        if (sectionTitle[section]) {
            fetchUsers();
        } else {
            navigate('/friends/all');
        }

        return () => {
            console.log(`Friends:${section || ''} componentWillUnmount()`);
        };
    }, [fetchUsers, navigate, section]);

    return (
        <div className="ibox w-100">
            <div className="ibox-content">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th><div className="m-t-xs">{isLoading ? '' : viewState.sectionTitle}</div></th>
                            <th className="text-right">
                                <div className="d-none d-sm-block">
                                    <button className="btn btn-w-s btn-default btn-outline btn-xs m-xxs" onClick={() => updateUsers('online')} type="button">Çevrimiçi</button>
                                    <button className="btn btn-w-s btn-default btn-outline btn-xs m-xxs" onClick={() => updateUsers('all')} type="button">Tümü</button>
                                    <button className="btn btn-w-s btn-default btn-outline btn-xs m-xxs" onClick={() => updateUsers('waiting')} type="button">Bekleyen</button>
                                    <button className="btn btn-w-s btn-default btn-outline btn-xs m-xxs" onClick={() => updateUsers('blocked')} type="button">Engellenen</button>
                                    <button className="btn btn-w-s btn-primary btn-outline btn-xs m-xxs" onClick={() => setIsOpen(true)} type="button">Arkadaş Ekle</button>
                                </div>
                                <button aria-expanded="false" className="btn btn-default btn-outline btn-xs m-xxs m-r-xs d-sm-none" data-toggle="dropdown" title="Liste"><i className="fa fa-ellipsis-v"></i></button>
                                <ul className="dropdown-menu" x-placement="bottom-start">
                                    <li><button className="dropdown-item w-100 p-sm" onClick={() => updateUsers('online')} title="Çevrimiçi"><i className="fa fa-user m-r-sm"></i>Çevrimiçi</button></li>
                                    <li><button className="dropdown-item w-100 p-sm" onClick={() => updateUsers('all')} title="Tümü"><i className="fa fa-users m-r-sm"></i>Tümü</button></li>
                                    <li><button className="dropdown-item w-100 p-sm" onClick={() => updateUsers('waiting')} title="Bekleyen"><i className="fa fa-paper-plane m-r-sm"></i>Bekleyen</button></li>
                                    <li><button className="dropdown-item w-100 p-sm" onClick={() => updateUsers('blocked')} title="Engellenen"><i className="fa fa-ban m-r-sm"></i>Engellenen</button></li>
                                    <li><button className="dropdown-item w-100 p-sm" onClick={() => setIsOpen(true)} title="Arkadaş Ekle"><i className="fa fa-plus-circle m-r-sm"></i>Arkadaş Ekle</button></li>
                                </ul>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ['all', 'online'].includes(viewState.currentSection) ?
                                viewState.users.map(userInfo =>
                                    <tr key={userInfo.username}>
                                        <td><div className="m-t-xs">{presenceIcons[userInfo.presenceStatus]}{userInfo.username}</div></td>
                                        <td align="right">
                                            <button aria-expanded="false" className="btn btn-xs d-none" title="Mesaj Gönder"><i className="fa fa-envelope"></i></button>
                                            <button aria-expanded="false" className="btn btn-xs d-none" title="Sesli Arama Başlat"><i className="fa fa-phone"></i></button>
                                            <button aria-expanded="false" className="btn btn-xs d-none" title="Görüntülü Arama Başlat"><i className="fa fa-video-camera"></i></button>
                                            <button aria-expanded="false" className="btn btn-xs d-none" title="Ekran Paylaş"><i className="fa fa-laptop"></i></button>
                                            <button aria-expanded="false" className="btn btn-xs m-r-xs" data-toggle="dropdown" title="Diğer"><i className="fa fa-ellipsis-v"></i></button>
                                            <ul className="dropdown-menu" x-placement="bottom-start">
                                                <li><button className="dropdown-item w-100 p-sm" onClick={() => remove(userInfo)} title="Arkadaşlarımdan Çıkar"><i className="fa fa-trash m-r-sm"></i>Arkadaşlarımdan Çıkar</button></li>
                                                <li><button className="dropdown-item w-100 p-sm" onClick={() => block(userInfo)} title="Engelle"><i className="fa fa-ban m-r-sm"></i>Engelle</button></li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                                : viewState.currentSection === 'waiting' ?
                                    viewState.users.map(userInfo =>
                                        <tr key={userInfo.username}>
                                            <td><div className="m-t-xs">{userInfo.username}</div></td>
                                            <td align="right">
                                                {userInfo.requestType === 'in' ? <div><button className="btn btn-primary btn-rounded btn-outline btn-xs m-xxs" onClick={() => accept(userInfo)} type="button"><i className="fa fa-plus-circle"></i> Kabul et</button> <button className="btn btn-danger btn-rounded btn-outline btn-xs m-xxs" onClick={() => remove(userInfo)} type="button"><i className="fa fa-minus-circle"></i> Reddet</button></div> : <div><button className="btn btn-warning btn-rounded btn-outline btn-xs m-xxs" onClick={() => remove(userInfo)} type="button"><i className="fa fa-times-circle"></i> İptal et</button></div>}
                                            </td>
                                        </tr>
                                    )
                                    : viewState.currentSection === 'blocked' ?
                                        viewState.users.map(userInfo =>
                                            <tr key={userInfo.username}>
                                                <td><div className="m-t-xs">{userInfo.username}</div></td>
                                                <td align="right">
                                                    <button className="btn btn-warning btn-rounded btn-outline btn-xs m-xxs" onClick={() => unblock(userInfo)} type="button"><i className="fa fa-ban"></i> Engeli kaldır</button>
                                                </td>
                                            </tr>
                                        )
                                        : ''
                        }
                        {viewState.users.length === 0 ? <tr><td align="center" colSpan="2">{viewState.emptyStateMessage}</td></tr> : ''}
                    </tbody>
                </table>
            </div>
            {isOpen && (
                <AddFriendModal
                    cancel={() => setIsOpen(false)}
                    success={() => {
                        setIsOpen(false);
                        updateUsers('waiting');
                    }}
                />
            )}
        </div>
    );
};

export default Friends;