import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import swal from 'sweetalert';

import config from '../../app/config';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { setCookie } from '../../common/utils/cookies';

import {
    initializeSession
} from '../../app/slices/sessionSlice';

const InitializeGuestSession = () => {
    const dispatch = useDispatch();

    const guestLogin = useCallback(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${config.restApi}guestLogin`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                switch (responseData.status) {
                    case 200:
                        setCookie('sessionHash', responseData.guestInfo.sessionHash, 126230400);
                        setCookie('sessionType', 'guest', 126230400);

                        dispatch(initializeSession({ sessionInfo: responseData.guestInfo, sessionType: 'guest' }));
                        break;
                    case 403:
                        swal({
                            dangerMode: true,
                            icon: 'warning',
                            title: 'Hay Aksi',
                            text: responseData.message,
                            buttons: 'Yeniden Dene'
                        }).then(() => guestLogin());
                        break;
                    case 500:
                        swal({
                            dangerMode: true,
                            icon: 'error',
                            title: 'Hata',
                            text: responseData.message,
                            buttons: 'Yeniden Dene'
                        }).then(() => guestLogin());
                        break;
                    default:
                        console.log(responseData);
                        break;
                }
            })
            .catch(() => swal({
                dangerMode: true,
                icon: 'error',
                title: 'Bağlantı Hatası',
                text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                buttons: 'Yeniden Dene'
            }).then(() => guestLogin()));
    }, [dispatch]);

    useEffect(() => {
        console.log('InitializeGuestSession: componentDidMount()');

        guestLogin();

        return () => {
            console.log('InitializeGuestSession: componentWillUnmount()');
        };
    }, [guestLogin]);

    return <LoadingSpinner />;
};

export default InitializeGuestSession;