import { createContext, useContext } from 'react';

import soundManagerInstance from '../services/soundManager';

const SoundManagerContext = createContext(null);

export const SoundManagerProvider = ({ children }) => {
    return (
        <SoundManagerContext.Provider value={soundManagerInstance}>
            {children}
        </SoundManagerContext.Provider>
    );
};

export const useSoundManager = () => useContext(SoundManagerContext);