import store from '../store';

import {
    setCamera,
    setDesktop,
    setMicrophone
} from '../slices/mediaStreamSlice';

class MediaStreamService {
    static instance = null;

    constructor() {
        if (MediaStreamService.instance) {
            return MediaStreamService.instance;
        }

        this.audioContext = null;
        this.noiseModule = null;
        this.scriptProcessor = null;

        this.desktopStream = null;
        this.userAudioStream = null;
        this.userVideoStream = null;

        this.mediaStreams = new Map();

        MediaStreamService.instance = this;
    }

    async startDesktop() {
        if (this.mediaStreams.has('desktop')) {
            return null;
        }

        if (!this.isDisplayMediaSupported()) {
            console.error('Tarayıcınız ekran paylaşımını desteklemiyor.');
            return null;
        }

        try {
            this.mediaStreams.set('desktop', '');

            if (window.electron) {
                const mediaConstraints = await window.electron.createMediaConstraints();

                this.desktopStream = await navigator.mediaDevices.getUserMedia(mediaConstraints)
            } else {
                const mediaConstraints = {
                    audio: {
                        autoGainControl: false,
                        echoCancellation: true,
                        noiseSuppression: false,

                        channelCount: 2,
                        latency: 0.01,
                        sampleSize: { max: 24 }
                    }, video: {
                        frameRate: { max: 60 }
                    }
                };

                this.desktopStream = await navigator.mediaDevices.getDisplayMedia(mediaConstraints);
            }

            this.desktopStream.getVideoTracks()[0].onended = () => this.stopDesktop();

            store.dispatch(setDesktop(true));
            console.log('Ekran açıldı');

            return this.desktopStream;
        } catch (error) {
            this.handleMediaError(error, 'Masaüstü');
            this.stopDesktop();
        }

        return null;
    }

    async startUserAudio(deviceId = null) {
        if (this.mediaStreams.has('userAudio')) {
            return null;
        }

        if (!this.isUserMediaSupported()) {
            console.error('Tarayıcınız mikrofon kullanımını desteklemiyor.');
            return null;
        }

        const noiseSuppression = localStorage.getItem('noiseSuppression') === 'true';

        const mediaConstraints = {
            audio: {
                deviceId: deviceId ? { exact: deviceId } : undefined,

                autoGainControl: !noiseSuppression,
                echoCancellation: true,
                noiseSuppression: !noiseSuppression,

                channelCount: 1,
                latency: 0.01,
                sampleSize: 16
            },
            video: false
        };

        try {
            this.mediaStreams.set('userAudio', '');

            const mediaStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
            mediaStream.getAudioTracks()[0].onended = () => this.stopUserAudio();

            if (noiseSuppression) {
                this.noiseModule = {
                    noExitRuntime: true,
                    noInitialRun: true,
                    preInit: [],
                    preRun: [],
                    postRun: [function () {
                        console.log(`Loaded Javascript Module OK`);
                    }],
                    memoryInitializerPrefixURL: "bin/",
                    arguments: ['input.ivf', 'output.raw']
                };

                window.NoiseModule(this.noiseModule);

                this.noiseModule.st = this.noiseModule._rnnoise_create();
                this.noiseModule.ptr = this.noiseModule._malloc(480 * 4);

                this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const source = this.audioContext.createMediaStreamSource(mediaStream);
                this.scriptProcessor = this.audioContext.createScriptProcessor(1024, 1, 1);
                const destination = this.audioContext.createMediaStreamDestination();

                const inputBuffer = [];
                const outputBuffer = [];
                const frame = new Float32Array(480);
                const { ptr, st, HEAPF32, _rnnoise_process_frame } = this.noiseModule;

                this.scriptProcessor.onaudioprocess = (e) => {
                    const input = e.inputBuffer.getChannelData(0);
                    const output = e.outputBuffer.getChannelData(0);

                    for (let i = 0; i < input.length; i++) inputBuffer.push(input[i]);

                    while (inputBuffer.length >= 480) {
                        for (let i = 0; i < 480; i++) {
                            frame[i] = inputBuffer.shift();
                            HEAPF32[(ptr >> 2) + i] = frame[i] * 32768;
                        }
                        _rnnoise_process_frame(st, ptr, ptr);
                        for (let i = 0; i < 480; i++) {
                            outputBuffer.push(HEAPF32[(ptr >> 2) + i] / 32768);
                        }
                    }

                    for (let i = 0; i < output.length; i++) {
                        output[i] = outputBuffer.length ? outputBuffer.shift() : 0;
                    }
                };

                source.connect(this.scriptProcessor);
                this.scriptProcessor.connect(destination);

                this.userAudioStream = destination.stream;
            } else {
                this.userAudioStream = mediaStream;
            }

            store.dispatch(setMicrophone(true));
            console.log('Mikrofon açıldı');

            return this.userAudioStream;
        } catch (error) {
            this.handleMediaError(error, 'Mikrofon');
            this.stopUserAudio();
        }

        return null;
    }

    async startUserVideo(deviceId = null) {
        if (this.mediaStreams.has('userVideo')) {
            return null;
        }

        if (!this.isUserMediaSupported()) {
            console.error('Tarayıcınız kamera kullanımını desteklemiyor.');
            return null;
        }

        const mediaConstraints = {
            audio: false,
            video: {
                deviceId: deviceId ? { exact: deviceId } : undefined,

                frameRate: { max: 30 },
                width: { min: 640, ideal: 1920 },
                height: { min: 360, ideal: 1080 }
            }
        };

        try {
            this.mediaStreams.set('userVideo', '');

            this.userVideoStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
            this.userVideoStream.getVideoTracks()[0].onended = () => this.stopUserVideo();

            store.dispatch(setCamera(true));
            console.log('Kamera açıldı');

            return this.userVideoStream;
        } catch (error) {
            this.handleMediaError(error, 'Kamera');
            this.stopUserVideo();
        }

        return null;
    }

    getDesktopStream() {
        return this.desktopStream;
    }

    getUserAudioStream() {
        return this.userAudioStream;
    }

    getUserVideoStream() {
        return this.userVideoStream;
    }

    stopDesktop() {
        if (this.mediaStreams.has('desktop')) {
            this.desktopStream = this.stopMediaStream(this.desktopStream);
            this.mediaStreams.delete('desktop');

            store.dispatch(setDesktop(false));
            console.log('Ekran kapatıldı');
        }
    }

    stopUserAudio() {
        if (this.mediaStreams.has('userAudio')) {
            this.userAudioStream = this.stopMediaStream(this.userAudioStream);
            this.mediaStreams.delete('userAudio');

            if (this.scriptProcessor) {
                this.scriptProcessor.disconnect();
                this.scriptProcessor.onaudioprocess = null;
                this.scriptProcessor = null;

            }

            if (this.audioContext) {
                this.audioContext.close();
                this.audioContext = null;
            }

            if (this.noiseModule) {
                if (this.noiseModule.st) {
                    this.noiseModule._rnnoise_destroy(this.noiseModule.st);
                }

                if (this.noiseModule.ptr) {
                    this.noiseModule._free(this.noiseModule.ptr);
                }

                this.noiseModule.st = null;
                this.noiseModule.ptr = null;
                this.noiseModule = null;
            }

            store.dispatch(setMicrophone(false));
            console.log('Mikrofon kapatıldı');
        }
    }

    stopUserVideo() {
        if (this.mediaStreams.has('userVideo')) {
            this.userVideoStream = this.stopMediaStream(this.userVideoStream);
            this.mediaStreams.delete('userVideo');

            store.dispatch(setCamera(false));
            console.log('Kamera kapatıldı');
        }
    }

    stopMediaStream(mediaStream) {
        if (this.isMediaStreamActive(mediaStream)) {
            try {
                mediaStream.getTracks().forEach(track => {
                    if (typeof track.stop === 'function') {
                        track.stop();
                    }
                });
            } catch (error) {
                console.warn('MediaStream kapatma hatası:', error);
            }
        }

        return null;
    }

    destroy() {
        this.stopDesktop();
        this.stopUserAudio();
        this.stopUserVideo();

        console.log('Tüm medya streamleri ve eventler kapatıldı.');
    }

    isDisplayMediaSupported() {
        return !!(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia);
    }

    isUserMediaSupported() {
        return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    }

    isMediaStreamActive(mediaStream) {
        return mediaStream && mediaStream.active && mediaStream.getTracks && mediaStream.getTracks().length > 0;
    }

    handleMediaError(error, type) {
        const messages = {
            'NotAllowedError': `${type} erişimine izin verilmedi! Lütfen tarayıcı izinlerini kontrol edin.`,
            'NotFoundError': `Bağlı bir ${type.toLowerCase()} bulunamadı.`,
            'OverconstrainedError': `${type} için uygun bir cihaz bulunamadı.`,
            'AbortError': `${type} erişimi iptal edildi.`,
            'SecurityError': `${type} güvenlik nedeniyle engellendi.`
        };

        console.error(messages[error.name] || `${type} açma hatası:`, error);
    }
}

const mediaStreamInstance = new MediaStreamService()

export default mediaStreamInstance;