import { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { setAppElement } from 'react-modal';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid';

import config from './app/config';
import store from './app/store';

import Providers from './app/context/Providers';

import mediasoupInstance from './app/services/mediasoup';
import soundManagerInstance from './app/services/soundManager';

import LoadingSpinner from './common/components/LoadingSpinner';
import { getCookie, removeCookie, setCookie } from './common/utils/cookies';

import GuestFrame from './frame/GuestFrame';
import UserFrame from './frame/UserFrame';

import CreateRoom from './pages/CreateRoom/CreateRoom';
import EditProfile from './pages/EditProfile/EditProfile';
import EmailActivation from './pages/EmailActivation/EmailActivation';
import FindRoom from './pages/FindRoom/FindRoom';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Friends from './pages/Friends/Friends';
import InitializeGuestSession from './pages/InitializeGuestSession/InitializeGuestSession';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import MagicLink from './pages/MagicLink/MagicLink';
import MagicLogin from './pages/MagicLogin/MagicLogin';
import Messages from './pages/Messages/Messages';
import Profile from './pages/Profile/Profile';
import Register from './pages/Register/Register';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Settings from './pages/Settings/Settings';
import TemporaryRoom from './pages/TemporaryRoom/TemporaryRoom';

import {
    setFocus
} from './app/slices/appSlice';

import {
    initializeSession
} from './app/slices/sessionSlice';

const withGuestFrame = (Component) => <GuestFrame><Providers><Component /></Providers></GuestFrame>;
const withUserFrame = (Component) => <UserFrame><Providers><Component /></Providers></UserFrame>;
const renderRoute = (route, sessionType) => {
    const { forAnonymous, forGuest, forUser, anonymousRedirect, guestRedirect, userRedirect } = route;

    switch (sessionType) {
        case 'guest':
            if (forGuest !== undefined) {
                return forGuest;
            } else {
                return <Navigate to={guestRedirect || "/"} />;
            }
        case 'user':
            if (forUser !== undefined) {
                return forUser;
            } else {
                return <Navigate to={userRedirect || "/"} />;
            }
        default:
            if (forAnonymous !== undefined) {
                return forAnonymous;
            } else {
                return <Navigate to={anonymousRedirect || "/"} />;
            }
    }
};

const routes = [
    {
        path: "/createRoom",
        forGuest: withGuestFrame(CreateRoom),
        forUser: withUserFrame(CreateRoom)
    },
    {
        path: "/findRoom",
        forGuest: withGuestFrame(FindRoom),
        forUser: withUserFrame(FindRoom)
    },
    {
        path: "/editProfile",
        forUser: withUserFrame(EditProfile)
    },
    {
        path: "/friends",
        forUser: withUserFrame(Friends)
    },
    {
        path: "/friends/:section",
        forUser: withUserFrame(Friends)
    },
    {
        path: "/messages",
        forUser: withUserFrame(Messages)
    },
    {
        path: "/settings",
        forGuest: withGuestFrame(Settings),
        forUser: withUserFrame(Settings)
    },
    {
        path: "/emailActivation",
        forAnonymous: <EmailActivation />,
        forGuest: <EmailActivation />,
        forUser: <EmailActivation />
    },
    {
        path: "/forgotPassword",
        forAnonymous: <ForgotPassword />,
        forGuest: <ForgotPassword />
    },
    {
        path: "/login",
        forAnonymous: <Login />,
        forGuest: <Login />
    },
    {
        path: "/logout",
        forGuest: <Logout />,
        forUser: <Logout />
    },
    {
        path: "/magicLink",
        forAnonymous: <MagicLink />,
        forGuest: <MagicLink />
    },
    {
        path: "/magicLogin",
        forAnonymous: <MagicLogin />,
        forGuest: <MagicLogin />
    },
    {
        path: "/profile",
        forUser: withUserFrame(Profile)
    },
    {
        path: "/register",
        forAnonymous: <Register />,
        forGuest: <Register />
    },
    {
        path: "/resetPassword",
        forAnonymous: <ResetPassword />,
        forGuest: <ResetPassword />
    },
    {
        path: "/:room",
        forAnonymous: <InitializeGuestSession />,
        forGuest: withGuestFrame(TemporaryRoom),
        forUser: withUserFrame(TemporaryRoom)
    },
    {
        path: "/",
        forAnonymous: <Login />,
        forGuest: withGuestFrame(CreateRoom),
        forUser: withUserFrame(Profile)
    }
];

const Index = () => {
    const dispatch = useDispatch();
    const { sessionType } = useSelector((state) => state.session);

    const [isLoading, setIsLoading] = useState(true);

    const clearSession = useCallback(() => {
        removeCookie('sessionHash');
        removeCookie('sessionType');
    }, []);

    const checkSession = useCallback(() => {
        const deviceId = getCookie('deviceId', uuidv4());
        const sessionHash = getCookie('sessionHash', '');
        const sessionType = getCookie('sessionType', '');

        setCookie('deviceId', deviceId, 126230400);

        if (sessionHash && sessionType) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionHash}`
                }
            };

            fetch(`${config.restApi}${sessionType === 'user' ? 'userInfo' : 'guestInfo'}`, requestOptions)
                .then((response) => response.json())
                .then((responseData) => {
                    switch (responseData.status) {
                        case 200:
                            dispatch(initializeSession({ sessionInfo: sessionType === 'user' ? responseData.userInfo : responseData.guestInfo, sessionType }));

                            setIsLoading(false);
                            break;
                        case 400:
                            clearSession();

                            setIsLoading(false);
                            break;
                        case 403:
                            swal({
                                dangerMode: true,
                                icon: 'warning',
                                title: 'Hay Aksi',
                                text: responseData.message,
                                buttons: 'Yeniden Dene'
                            }).then(() => checkSession());
                            break;
                        default:
                            console.log(responseData);
                            break;
                    }
                })
                .catch(() => swal({
                    dangerMode: true,
                    icon: 'error',
                    title: 'Bağlantı Hatası',
                    text: 'Lütfen internet bağlantınızı kontrol ediniz.',
                    buttons: 'Yeniden Dene'
                }).then(() => checkSession()));
        } else {
            clearSession();

            setIsLoading(false);
        }
    }, [dispatch, clearSession]);

    useEffect(() => {
        console.log('App: componentDidMount()');

        const handleBlur = () => dispatch(setFocus(false));
        const handleFocus = () => dispatch(setFocus(true));

        checkSession();

        window.addEventListener('blur', handleBlur);
        window.addEventListener('focus', handleFocus);

        (async () => await soundManagerInstance.setSinkId(await mediasoupInstance.getSelectedDeviceId('audiooutput')))();

        return () => {
            console.log('App: componentWillUnmount()');

            window.removeEventListener('blur', handleBlur);
            window.removeEventListener('focus', handleFocus);
        };
    }, [dispatch, checkSession]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <BrowserRouter>
            <Routes>
                {routes.map((routeInfo, index) => <Route key={index} path={routeInfo.path} element={renderRoute(routeInfo, sessionType)} />)}
            </Routes>
        </BrowserRouter>
    );
};

const root = document.getElementById('root');
createRoot(root).render(<Provider store={store}><Index /></Provider>);
setAppElement(root);